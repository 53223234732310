.downloadsresources{
     padding-top: 40px;
     .dwnld{
          width: 70%;
          ul{
               display: flex; flex-direction: row; flex-wrap: wrap; gap: 24px;
               width: 100%;
                    @media (max-width: 991.98px) {
                         margin: 0px -10px 20px -10px;
                    }
               li{
                   list-style: none; text-align: center; margin-bottom: 30px;
                    width: calc(33.33% - 16px); 
                    max-width: calc(33.33% - 16px);
                    flex-grow: 1;
                    
                         @media (max-width: 991.98px) {
                               margin-bottom: 40px;
                         }
                         @media (max-width: 767.98px) {
                              width:calc(50% - 16px);
                              max-width:calc(50% - 16px) ;
                         }
                         @media (max-width: 575.98px) {
                              width:100%;
                              max-width: 100%;
                              
                         }
                         .img-contain{
                              width: 100%;
                              height: 230px;
                              overflow: hidden;
                              border-radius: 8px;
                              border: 1px solid #ebebeb;
                              display: flex;
                              align-items: center;
                              img{
                                   height: 100%;
                                   object-fit: cover;
                                   width: 100%; 
                             }
                             iframe{
                              width: 100%;
                              height: 100%;
                             }
                         }
                    
                    .dwnld-details{
                         display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: left; padding: 10px 0px;
                         a{
                              line-height: 21px; display: flex; align-items: center;
                              width: 100%;
                              justify-content: space-between;
                         }
                              img{
                                   height: 17px; width: 17px; object-fit: cover;  
                                   border: 0px solid; padding: 0px;
                                   margin: unset;
                              }
                              
                    }
               }
          }
     }
     .dnr-contain{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .dnr-filter{
               width: 30%;
               .input-select {
                    border-radius: 8px;
                    border: 1px solid #D0D5DD;
                    background: #FFF;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    height: 36px;
                    padding: 0px 8px;
                    width: 100%;
                    max-width: 300px;
                    margin-bottom: 16px
                }
          }
     }
}
.dnr-contain .mob-block-only {
     display: none;
 }
.dnr-top-nav{
     margin-bottom: 40px;
     display: flex;
     width: 100%;
     .nav-contain{
          display: flex;
          flex-wrap: wrap;
          margin-left: auto;
          .nav{
               background-color: #efeff0;
               line-height: 1;
               font-size: 16px;
               padding: 6px 24px;
               border-radius: 100px;
               margin-right: 20px;
               border: 1px solid transparent;
               cursor: pointer;
               &.active{
                    border: 1px solid #00a0e7;
                    color: #00a0e7;
                    background-color: #fff;
               }
               &:nth-last-child(1){
                    margin-right: 0px;
               }
          }
          
     }
}
.filter-list{
     display: flex;
     flex-direction: column;
     li{
          list-style: none;
          border-bottom: 2px solid #ebebeb;
          padding-bottom: 6px;
          margin-bottom: 6px;
          width: 224px;
          cursor: pointer;
          &.active{
               color: #00a0e7;
               border-bottom: 2px solid#00a0e7;
          }
     }
}

@media (max-width: 991.98px) {
     .downloadsresources{
          .dnr-contain{
               .dnr-filter{
                    width: 100%;
               }
          }
          .dwnld{
               width: 100%;
          }
     } 
     .dnr-top-nav .nav-contain{
          gap: 10px 16px;
          .nav{
               margin:unset;
          }
     }
     .dnr-contain .mob-block-only {
          display: block;
      }
      .dnr-contain .web-block-only {
          display: none;
      } 
}
@media (max-width: 575.98px) {
     .downloadsresources .dwnld ul {
          margin: unset;
      }
}
