.banner-bg{
     width: 100%; max-height: 630px; min-height: 630px; background-repeat: no-repeat; background-size: cover; position: relative;
          @media (max-width: 767.98px) {
               min-height: 90vh;                    
          }
     .container{
          .banner-bg-inner{
                    content: ""; position: absolute;  top: 25%; left: 25%; text-align: center;
                         @media (max-width: 767.98px) {
                              top: 20%;                  
                         }
                         @media (max-width: 575.98px) {
                              top: 25%;                  
                         }
                         @media (max-width: 480.98px) {
                              top: 20%; left: 8%;                 
                         }
                    h3{
                         font-size: $f-34; line-height: 42px; color: #469bdb; font-weight: $weight-400; margin-bottom: 20px;
                              @media (max-width: 991.98px) {
                                   font-size: $f-22; line-height: 32px;
                              }
                    }
                    h5{
                         font-size: $f-26; line-height: 38px; color: $c-white; font-weight: $weight-400; margin-bottom: 10px;
                              @media (max-width: 991.98px) {
                                   font-size: $f-18; line-height: 28px;
                              }
                    }   
                    img{
                         margin: 0 auto; max-height: 150px; min-height: 150px; margin-bottom: 30px;
                              @media (max-width: 991.98px) {
                                   max-height: 120px; min-height: 120px;                  
                              }
                              @media (max-width: 767.98px) {
                                   max-height: 100px; min-height: 100px;                  
                              }
                    } 
          }
     }
}
.upcoming-event{
     .event-mrgn-btm{
          margin-bottom: 140px;
               @media (max-width: 991.98px) {
                    margin-bottom: 80px; margin-top: 0px;
               }
               @media (max-width: 575.98px) {
                    margin-bottom: 50px; margin-top: 0px;
               }
          .event-heading{
               color: #404041; margin-bottom: 30px; margin-top: 0px;
               @media (max-width: 991.98px) {
                    margin-bottom: 40px; margin-top: 0px;
               }
               @media (max-width: 767.98px) {
                    margin-bottom: 30px; margin-top: 0px;
               }
          }
     }
     .event-inner-sec{
          display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;  
               @media (max-width: 767.98px) {
                    flex-direction: column; flex-wrap: wrap;
               }
          .event-inner{
               width: 50%; padding: 20px; max-width: 509px; background-color: $c-solitude; 
                    @media (max-width: 1199.98px) {
                         max-width: 450px;
                    }
                    @media (max-width: 991.98px) {
                         max-width: 380px;
                    }
                    @media (max-width: 800px) {
                         max-width: 360px;
                    }
                    @media (max-width: 767.98px) {
                         max-width: 100%; width: 100%; margin-bottom: 20px;
                    }
                    @media (max-width: 575.98px) {
                         max-width: 100%; width: 100%; padding: 15px;
                    }
               &::nth-child(odd) {
                  margin-right:62px;  
               }
               &::nth-child(even) {
                    margin-left:62px;
               }
               img{
                    min-height: 210px; max-height: 210px; width: 100%; object-fit: contain;
               }
               .event-dtls{
                    padding-top: 30px; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: 
                    space-between;  
                         @media (max-width: 575.98px) {
                              flex-direction:column; padding-top: 15px;
                         }
                    .event-left{
                          width: 25%;
                              @media (max-width: 575.98px) {
                                  width: 100%;
                              }
                          h3{
                              line-height: 26px;
                                   @media (max-width: 575.98px) {
                                        line-height: 24px;
                                   }
                          }
                    }
                    .event-right{
                         width: 75%;
                              @media (max-width: 575.98px) {
                                   width: 100%;
                              }
                         p{
                              font-weight: $weight-500; line-height: 30px;
                         }
                         .btn-logo{
                              display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; align-items: center;
                              .btn-midnight-blue{
                                   width: max-content;
                              }
                              img{
                                   min-height: 70px; max-height: 70px; width: 100%; max-width: 100px; object-fit: contain;
                                   @media (max-width: 575.98px) {
                                        min-height: 50px; max-height: 50px; width: 100%; max-width: 70px;
                                   }
                              }
                         }
                         
                    }
               }
          }
     }
     .previous-inner-sec{
           flex-direction: row; flex-wrap: wrap; justify-content: space-between;  
               @media (max-width: 767.98px) {
                    flex-direction: row; flex-wrap: wrap;
               }
               @media (max-width: 575.98px) {
                    flex-direction: column; flex-wrap: wrap;
               }
               .previous-content{
                    margin: 0 -85px;
                    @media (max-width: 1199.98px) {
                         margin: 0 -35px;
                    }
                    @media (max-width: 991.98px) {
                         margin: 0 -30px;
                    }
                    @media (max-width: 767.98px) {
                         margin: 0 -15px;
                    }
                    
                    .previous-inner{
                         padding: 0 85px;
                              @media (max-width: 1199.98px) {
                                   padding:0px 35px;
                              }
                              @media (max-width: 991.98px) {
                                   padding:0px 30px;
                              }
                              @media (max-width: 767.98px) {
                                   padding:0px 15px;
                              }
                              @media (max-width: 575.98px) {
                                   max-width: 100%; width: 100%; padding: 15px;
                              }
                         .event-dtls{
                              background-color: $c-solitude; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between;  
                              @media (max-width: 991.98px) {
                                   flex-direction: column;
                              }
                              .event-left{
                                   width: 39.68%;
                                   @media (max-width: 991.98px) {
                                        width: 100%;
                                   }
                                   img{
                                        max-height: 192px; min-height: 192px; height: 100%; width: 100%; object-fit: cover;
                                        @media (max-width: 991.98px) {
                                             min-height: 180px;  max-height: 180px; 
                                        } 
                                        @media (max-width: 575.98px) {
                                             min-height: 192px;  max-height: 230px;  
                                        } 
                                   }
                              }
                              .event-right{
                                   width: 60.32%; padding: 10px 15px; display: flex; flex-direction: column; justify-content: space-between;  
                                        @media (max-width: 991.98px) {
                                             width: 100%; 
                                        }
                                        @media (max-width: 575.98px) {
                                             width: 100%; padding:20px;
                                        }
                                   p{
                                        font-weight: $weight-500; line-height: 30px;
                                   }       
                                   button{
                                        width: max-content;
                                   }                  
                              }
                         }
                    }
               }
     }
     .slick-dots li button:before {
          color: #333673 ; opacity: 1; font-size: 10px;
     }
     li.slick-active button:before {
          opacity: 1; color: #85bbe4 !important;
     }
     .slick-dots{
          bottom: -40px; 
          @media (max-width: 767.98px) {
                bottom: -35px;
          }
     }
}
.video-event{
     img{
          height: auto;
          width: 100%;
          max-height: 650px;
          object-fit: unset;
          @media (max-width: 1399.98px) {
               object-fit: cover; min-height: auto;
          }
     }
}