.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 999;
}
.modal-img img{
    height: auto; width: auto; max-height: 80vh;
}
.close-btn{
    position: fixed; top: -25px; right: -25px; left: auto; z-index: 99; cursor: pointer; background-color: transparent; padding: 0;
}
.close-btn svg{ 
    fill: $c-white; stroke: $c-white; width: 25px; height: 25px; display: block;
}
.close-btn:hover svg{
    opacity: 0.7;
}
.custom-modal {
    position: fixed; top: 50%; left: 50%; right: auto; bottom: auto; transform: translate(-50%, -50%); border: none; padding: 0; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); background: #fff; width: auto; max-width: 80%; overflow: visible; animation: zoomIn 0.5s forwards;
}
@media (max-width: 768px) {
    .custom-modal {
      width: 100%;
    }
}  
.custom-modal.closing {
    animation: zoomOut 0.5s forwards;
}
@keyframes zoomIn {
    from {
        transform: translate(-50%, -50%) scale(0);
    }
    to {
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes zoomOut {
    from {
        transform: translate(-50%, -50%) scale(1);
    }
    to {
        transform: translate(-50%, -50%) scale(0);
    }
}