// Plans Section CSS START
.plan{
    padding: 76px 0 0 0;
    @media (max-width: 991.98px) {
        padding: 70px 0 0 0;
    }
    @media (max-width: 575.98px) {
        padding: 50px 0 0 0;
    }
    h2{
        color: $c-red2;
    }
}
.plans-wrap{
    display: flex; flex-wrap: wrap; margin: 0 -40px; padding-bottom: 80px;
    @media (max-width: 1399.98px) { 
        margin: 0 -20px;
    }
    @media (max-width: 1199.98px) { 
        margin: 0 -10px; padding-bottom: 70px;
    }
    @media (max-width: 575.98px) {
        margin: 0; padding-bottom: 50px;
    }
    .plan-item{
        padding: 40px; width: 33.33%;
        @media (max-width: 1199.98px) { 
            padding: 30px; 
        }
        @media (max-width: 991.98px) {
            width: 50%;
        }
        @media (max-width: 767.98px) {
            padding: 15px;
        }
        @media (max-width: 575.98px) {
            width: 100%;
        }
        .plan-inner{
            padding: 30px; background-color: $c-light-cyn-blue; position: relative; height: 100%;
            @media (max-width: 767.98px) {
                padding: 30px 20px 20px 20px;
            }
            span, li{
                color: $c-midnight-blue; display: block;
            }
            .offer-badge{
                background-color: #84bae3; padding: 6px 16px; position: absolute; top: 10px; left: -16px; font-weight: $weight-600; font-style: italic;
                @media (max-width: 767.98px) {
                    left: -10px; padding: 4px 10px;
                }
            }
            .speed{
                font-size: $f-25; font-weight: $weight-800; margin: 15px 0 18px 0;
                @media (max-width: 767.98px) {
                    font-size: $f-22; 
                }
            }
            ul{
                margin-bottom: 0;
                li{
                    line-height: 15px; margin-bottom: 5px; font-weight: $weight-500;
                }
            }
            .price{
                font-size: $f-25; font-weight: $weight-800; margin: 10px 0 13px 0;
                @media (max-width: 767.98px) {
                    font-size: $f-22; 
                }
                small{
                    font-size: $f-16; 
                    @media (max-width: 767.98px) {
                        font-size: 14px; 
                    }
                }
            }
        }
    }
}
// Plans Section CSS END

// Add-ons Section CSS START
.add-ons{
    text-align: center; color: $c-white;
    h2{
        color: $c-white;
    }
    .action-link{
        display: inline-block; transition: all 0.4s ease-in-out;
        img{
            border-radius: 12px; height: auto;
        }
    }
}
.ons-add{
    padding: 85px 0px 85px 0; 
}
// Add-ons Section CSS END

// Cashback section CSS START 
.cashback{
    padding: 90px 0; background-color: $c-midnight-blue; color: $c-white;
    @media (max-width: 991.98px) {
        padding: 70px 0;
    }
    @media (max-width: 575.98px) {
            padding: 50px 0;
    }
    h2{
        color: $c-white;
    }
    .cashback-wrap{
        display: flex; flex-wrap: wrap; justify-content: space-between;
        .lft-text{
            width: 50%; padding-right: 25px; display: flex; flex-wrap: wrap;
            @media (max-width: 991.98px) {
                width: 100%; padding: 0 0 40px 0; 
            }
            .condition{
                margin-top: auto; font-weight: $weight-300; font-style: italic; line-height: 21px;
            }
        }
        .rgt-form{
            width: 50%;
            @media (max-width: 991.98px) {
                width: 100%;
            }
        }
    }
}
// Cashback section CSS END

// Modal CSS START
.modal {
    position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 50;
    
    .modal-dialog{
        transform: translate(-50%, -50%); position: absolute; top: 50%; left: 50%; max-width: 750px; width: 100%; padding: 20px;
    }
    .modal-content{
        padding: 30px 35px 25px 35px; background-color: $c-light-cyn-blue; border-radius: 5px; max-height: 80vh; overflow-y: auto; 
         transition: margin 0.4s ease-in-out;
        @media (max-width: 991.98px) {
            padding: 15px ;
        }
        @media (max-width: 767.98px) {
            padding: 15px 25px 15px 25px;
        }
        .item{
            label{
                text-align: right; padding-right: 14px;
                @media (max-width: 767.98px) {
                    text-align: left;
                }
            }
        }
    }
    .close {
        position: absolute; top: 10px; right: 10px; cursor: pointer;
    }    
    *{
        scrollbar-width: auto; scrollbar-color: #bbb;
    }
    *::-webkit-scrollbar {
        width: 10px;
    }
    *::-webkit-scrollbar-thumb {
        background-color: #bbb; border-radius: 10px;
    }
    &.show{
        .modal-content{
           
        }
    }
}

// Modal CSS END

