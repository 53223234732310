.full-width{
    &.align-left{
        text-align: left;
        h2, h3{
            max-width: 100%; margin-bottom: 22px;
        }
        h3, h4, h5, h6{
            color: $c-white; margin-bottom: 16px;
        }
        p{
            max-width: 100%;
        }
        ul{
            margin-left: 20px;
            li{
                color: $c-white; list-style-position: initial; margin-bottom: 12px;
            }
        }
    }
}

h2{
    span{
        display: block;
        .fontchange{
            display: inline-block; font-size: 68px; font-weight: $weight-400; padding-left: 7px;
            @media (max-width: 767.98px) { 
                font-size: 48px;
            }
        }
    }
}

// If have blue background and need text color white (use class - blue-bg) START
.blue-bg{
    background-color: $c-midnight-blue;
    h2, h3, h4, h5, span, li, p{
        color: $c-white;
    }
}
// (use class - blue-bg) END

/* Capabilities section CSS START
========================================================================== */
.about{
    .capabilities-inner{
        line-height: 0;
        @media (max-width: 991.98px) { 
            .iconborderbottom{
                display: block; margin: 20px 0; max-width: 100%;
                image{
                    width: 100%; max-width: 100%;
                }
            }
        }
        @media (max-width: 575.98px) { 
            .iconborderbottom{
                display: none;
            }
        }
        .capabil-frow{
            align-items: center;
            @media (max-width: 991.98px) { 
                border: 0; margin: 0 -15px; padding: 0;
            }
            .item{
                width: 33.33%; padding: 30px; position: relative; line-height: normal;
                @media (max-width: 991.98px) { 
                    padding: 15px;
                }
                @media (max-width: 575.98px) { 
                    width: 100%; padding: 24px 15px;
                }
                h4{
                    width: auto; padding: 0; font-size: $f-40; font-weight: $weight-600;
                    @media (max-width: 1199.98px) { 
                        font-size: $f-26;
                    }
                    @media (max-width: 767.98px) { 
                        font-size: $f-22;
                    }
                    @media (max-width: 575.98px) { 
                        border: 0;
                    }
                }
                span{
                    display: block; font-size: $f-26; color: $c-midnight-blue; font-weight: $weight-400; word-break: break-word;
                    @media (max-width: 1199.98px) { 
                        font-size: $f-20;
                    }
                    @media (max-width: 767.98px) { 
                        font-size: $f-18;
                    }
                }
                &::before{
                    content: ""; background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="4px" height="160px"><image x="0px" y="0px" width="4px" height="160px" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAACgCAQAAACBdQUNAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfnCgoRJDgmlukgAAABl0lEQVQ4y9WU0ZaaQBBELz2AIKI5J///j1lAUGFm8lD2rrJJ3vN26a7qqQYdMjnkNmNATQcGHPkpKDkJWm8F+l2l5uLio7eershZcKDetVa391QC88nRWxdKQXZXpnFXK0heibSYAWcySVEbggELgaNpGaIpOhgrC9AZRgEEo+MHsOkZKiNyB1qjopar5QRk5YFKp8PJMAKQjDNn1wCYkYnA2UhEVY500giSkdiA2gf2RiRJ09AIenrFSCS5sp+uPLW3MCoqnq/26fJAe+jc3nglvmkKwYWTwOQ0MvmPA+Pb5DdIf4/xH0HxbZ3h2+7FiysQBFdmwY37vyd/vvnRK9tL68FDMDHtPsqLPciu1mpsbIAZA4NrMhCMAgPuRkEpVybtxNF/vQUBWIxABVyNhVmtT1D47SXGxqo5M6M21ZxolBx8ziT7jauClTTAbCQKJVz4xfOvh/aCAws3Away9tqIgopRsHAX3Hj41XQVTCyCB7eda3RxZnYY3LX69TX6zTYJ7j55c/Hi9viVZ/BDJ4cPIJMtN5nfRniXZo2BNpgAAAAASUVORK5CYII=" /></svg>');
                    height: 100%; width: 4px; display: block; position: absolute; top: 50%; transform: translateY(-50%); right: 0; background-size: contain; background-repeat: no-repeat; background-position-y: center;
                    @media (max-width: 575.98px) { 
                        left: 50%; right: auto; transform: rotate(90deg); bottom: 0;
                    }
                }

                &:last-child{
                    &::before{
                        display: none;
                        @media (max-width: 575.98px) { 
                            display: block;
                        }
                    }
                }
            }
            &:last-child{
                .item{
                    &:last-child{
                        &::before{
                            display: none;
                        }
                    }
                }    
            }
        }
    }
}
/* Capabilities section CSS END */

/* Leadership section CSS START
========================================================================== */
.leadership{
    h2{
        text-align: center;
    }
    .leadership-wrap{
        margin-top: 40px;
        .item{
            margin-bottom: 100px; display: flex; flex-wrap: wrap; align-items: center;
            @media (max-width: 767.98px) { 
                margin-bottom: 40px; padding-bottom: 10px; position: relative;
                &::before{
                    content: ""; width: 50%; height: 1px; background-color: $c-light-cyn-blue; position: absolute; top: auto; bottom: 0; left: 50%; transform: translateX(-50%);
                }
            }
            .img{
                width: 35%; padding-right: 65px; text-align: center;
                @media (max-width: 991.98px) { 
                    padding-right: 20px;
                }
                @media (max-width: 767.98px) { 
                    width: 100%; padding: 0 !important;
                }
                figure{ 
                    width: 270px; height: 270px; border-radius: 100%; display: inline-flex; align-items: center; justify-content: center; overflow: hidden;
                    @media (max-width: 991.98px) {
                        width: 235px; height: 235px;
                    }
                    @media (max-width: 575.98px) {
                        width: 200px; height: 200px;
                    }
                    img{
                        max-height: 100%; width: auto !important; height: 100%; object-fit: cover; display: inline-block; z-index: 9;
                    }
                }
                .name{
                    margin-top: 20px; font-size: $f-25; font-weight: $weight-500;
                    @media (max-width: 767.98px) {
                        margin: 10px 0 15px 0;
                    }
                    span{
                        display: block; color: $c-midnight-blue;
                    }
                    .position{
                        font-size: $f-16; font-style: italic; margin-top: 8px;
                    }
                }
            }
            .content{
                width: 65%;
                @media (max-width: 767.98px) { 
                    width: 100%;
                }
                p{
                    color: $c-midnight-blue;
                }
            }

            &:nth-child(even){
                flex-direction: row-reverse;

                .img{
                    padding-left: 65px; padding-right: 0;
                    @media (max-width: 991.98px) {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
    .team-members{
        display: flex; flex-wrap: wrap; margin: 0 -30px;
        @media (max-width: 1399.98px) { 
            margin: 0 -20px;
        }
        @media (max-width: 767.98px) { 
            margin: 0 -10px;
        }
        .item-member{
            text-align: center; width: 25%; padding: 30px;
            @media (max-width: 1399.98px) { 
                padding: 20px;
            }
            @media (max-width: 991.98px) { 
                width: 33.33%;
            }
            @media (max-width: 767.98px) { 
                padding: 10px;
            }
            @media (max-width: 575.98px) { 
                width: 50%; padding: 15px 10px;
            }
            figure{ 
                width: 160px; height: 160px; border-radius: 100%; display: inline-flex; align-items: center; justify-content: center; overflow: hidden;
                @media (max-width: 767.98px) { 
                    width: 150px; height: 150px;
                }
                @media (max-width: 575.98px) { 
                    width: 110px; height: 110px;
                }
                img{
                    max-height: 100%; width: auto !important; height: 100%; object-fit: cover; display: inline-block; z-index: 9;
                }
            }
            .name{
                margin-top: 20px; font-size: $f-25; font-weight: $weight-500;
                @media (max-width: 767.98px) { 
                    font-size: $f-20; margin-top: 10px;
                }
                span{
                    display: block; color: $c-midnight-blue;
                }
                .position{
                    font-size: $f-16; font-style: italic; margin-top: 8px; line-height: 22px;
                    @media (max-width: 767.98px) { 
                        font-size: 15px; line-height: 20px;
                    }
                }
            }
        }
    }
}
/* leadership section CSS END */
 
/* Fostering Section CSS START 
========================================================================== */
.fostering{
    background-color: $c-solitude2;
    .description{
        font-size: $f-25; margin-bottom: 35px; color: $c-midnight-blue; font-weight: $weight-500; display: block;
        @media (max-width: 991.98px) { 
            font-size: $f-20;
        }
    }
    .bg-static{
        img{
            height: auto; width: auto;
        }
    }
}
/* Fostering Section CSS START */

/* Certification Section CSS START 
========================================================================== */
.certification{
    .certificate-wrap{
        display: flex; flex-wrap: wrap; align-items: start; justify-content: flex-start; max-width: 100%;
       
        .certi-item{
            width: 25%; padding: 18px 30px; text-align: center;
            @media (max-width: 767.98px) { 
                padding: 18px 20px; 
            }
            
            figure{ 
                width: 175px; height: 175px; display: inline-flex; align-items: center; justify-content: center;
                @media (max-width: 991.98px) { 
                    width: 135px; height: 135px;
                }
                
                @media (max-width: 575.98px) { 
                    width: 110px; height: 110px;
                }
                img{
                    max-height: 100%; width: auto !important; height: 100%; object-fit: contain; display: inline-block; z-index: 9;
                }
            }
            .certi-name{
                line-height: 22px; margin-top: 10px; display: block;
            }
        }
        @media (max-width: 991.98px) { 
            .certi-item{
                width: 33.33%;
            }
        }
        @media (max-width: 575.98px) { 
            .certi-item{
                width: 50%;
            }
        }
    }
}
/* Certification Section CSS START */

/* Rewards Section CSS START 
========================================================================== */
.rewards{
    background-color: $c-solitude2;
    .reward-item-wrap{
        margin-top: 40px;
        .ward-item{
            padding: 0 28px; border-right: 1px solid $c-white; height: 100%;
            figure{
                width: 100%; max-width: 100%; height: 178px; display: inline-flex; align-items: center; justify-content: center; background-color: $c-white;
                img{
                    max-height: 100%; width: auto; object-fit: contain; display: inline-block; z-index: 9; height: auto;
                }
            }
            .reward-name{
                margin-top: 24px; display: block; font-weight: $weight-600; color: $c-midnight-blue; line-height: 20px; min-height: 80px;
                @media (max-width: 575.98px) { 
                    min-height: auto;
                }
            }
        }
    }
}
/* Rewards Section CSS START */