/* Reset css START
   ========================================================================== */
   *{
    border: 0;
    outline: none;
    margin: 0;
    padding: 0;
 }
 
 *,
 *::before,
 *::after {
    box-sizing: border-box;
 }
 
 html{
     scroll-behavior: smooth;
 }
 
 input:focus, input:focus + label, button:focus, textarea:focus, select:focus, details:focus, [href]:focus, [tabindex]:not([tabindex="-1"]):focus, [contenteditable="true"]:focus{
     outline: none !important;
     outline-offset: 0 !important;
 }
 
 video{
     max-width: 100%;
 }
 
 img{
     max-width: 100%; display: block;
 }
 
 textarea {
     max-width: 100%;
     resize: vertical;
     font-family: $primary-font;
 }
 
 .form-label{
     cursor: default;
     
     small{
         vertical-align: text-top;  float: none;
     }
 }
 
 a{
     text-decoration: none;
     transition: all 0.3s ease-in-out;
     color: $c-midnight-blue;

     &:hover{
        color: $c-red1;
     }
 }
 
 h1, h2, h3, h4, h5, h6 {
     font-family: $primary-font; margin-bottom: 20px;
 }
 
 body{ 
     font-family: $primary-font;
     font-size: $p;
     -webkit-font-smoothing: subpixel-antialiased;
 }
 
 .container{
     max-width: 1230px; padding: 0 20px; margin: 0 auto;
 }
 
 h1{
     font-size: $h1; line-height: 49px; font-weight: $weight-400;
    @media (max-width: 991.98px) {
        font-size: $f-36; line-height: 46px;
    }
     @media (max-width: 575.98px) {
         font-size: $f-32; line-height: 42px;
     }
 }
 h2{
     font-size: $h2; line-height: 49px; color: $c-midnight-blue; font-weight: $weight-400;
     @media (max-width: 991.98px) {
        font-size: $f-36; line-height: 46px;
    }
     @media (max-width: 575.98px) {
         font-size: $f-32; line-height: 42px;
     }
 }
 h3{
     font-size: $h3; line-height: 42px; color: $c-midnight-blue; font-weight: $weight-400;
     @media (max-width: 991.98px) {
        font-size: $f-22; line-height: 32px;
    }
 }
 h4{
    font-size: $h4; line-height: 38px; color: $c-midnight-blue; font-weight: $weight-400;
    @media (max-width: 991.98px) {
        font-size: $f-20; line-height: 30px;
    }
 }
 h5{
    font-size: $h5; line-height: 34px; color: $c-midnight-blue; font-weight: $weight-400;
    @media (max-width: 991.98px) {
        font-size: $f-18; line-height: 28px;
    }
 }
 h6{
    font-size: $h6; line-height: 32px; color: $c-midnight-blue; font-weight: $weight-400;
    @media (max-width: 991.98px) {
        font-size: $f-16; line-height: 26px;
    }
 }
 p, ul li, ol li {
     font-size: $p; line-height: 34px; font-weight: $weight-500;
     @media (max-width: 575.98px) {
         line-height: 32px;
     }
 }
 p{
     margin-bottom: 12px; 
 }
 
 ul{
     list-style-type: disc;
     list-style-position: inside;
     margin-bottom: 12px;
 }
 
 ol{
     list-style-position: inside;
     margin-bottom: 12px;
 }
 
 .page-heading{
     font-size: $h1; line-height: 49px;
 }
 .sec-heading{
     font-size: $h1; line-height: 49px;
 }
 button{
    font-family: $primary-font;
 }
 .button{
     font-size: $f-16;
     color: $c-white;
     background-color: $c-dark-grayish;
     padding: 10px 33px 10px 18px;
     border-radius: 20px;
     position: relative;
     cursor: pointer;
     transition: all 0.3s ease-in-out;
     font-family: $primary-font;
     display: inline-block;
 
     @media (max-width: 991.98px) {
         padding: 8px 33px 8px 18px;
     }
 
     &::before{
         content: '';
         background: $c-white;
         width: 7px;
         height: 2px;
         position: absolute;
         top: 50%;
         right: 16px;
         border-radius: 2px;
         margin-top: -3px;
         transform: rotate(43deg);
         transition: all 0.3s ease-in-out;
     }
     &::after{
         content: '';
         background: $c-white;
         width: 7px;
         height: 2px;
         position: absolute;
         top: 50%;
         right: 16px;
         margin-top: 1px;
         border-radius: 2px;
         transform: rotate(-43deg);
         transition: all 0.3s ease-in-out;
     }
 
     &:hover{
         background-color: $c-midnight-blue; color: $c-white;
     }
 
     &.btn-white{
         background-color: $c-white; color: $c-dark-grayish;
 
         &::after, &::before{
             background-color: $c-dark-grayish;
         }
 
         &:hover{
             background-color: $c-dark-grayish; color: $c-white;
             &::after, &::before{
                 background-color: $c-white;
             }
         }
     }
 
     &.btn-light-cyn-blue{
         background-color: $c-light-cyn-blue; color: $c-mischka;
 
         &::after, &::before{
             background-color: $c-mischka;
         }
 
         &:hover{
             background-color: $c-dark-grayish; color: $c-white;
             &::after, &::before{
                 background-color: $c-white;
             }
         }
     }
 
     &.btn-midnight-blue{
         background-color: $c-midnight-blue; color: $c-white;
 
         &::after, &::before{
             background-color: $c-white;
         }
 
         &:hover{
             background-color: $c-dark-grayish; color: $c-white;
             &::after, &::before{
                 background-color: $c-white;
             }
         }
     }
     &.btn-red-whitebg{
        background-color: $c-white; color: $c-red1; font-weight: $weight-700; font-size: $f-25; padding: 11px 23px; line-height: 20px;
        @media (max-width: 575.98px) {
            font-size: $f-20;
        }
        &::after, &::before{
            display: none;
        }
        
        &:hover{
            background-color: $c-midnight-blue; color: $c-white;
        }
     } 

    &.btn-advantage{
        color: $c-midnight-blue; padding: 11px 22px; background-color: $c-pacific-blue2; font-weight: $weight-700;
        &::after, &::before{
            display: none;
        }
        &:hover{
            background-color: $c-midnight-blue; color: $c-white;
        }
    }

    &.btn-pacific-blue{
        color: $c-midnight-blue; padding: 11px 22px; background-color: $c-pacific-blue2; font-weight: $weight-700; font-size: $f-25; line-height: 20px;
        &::after, &::before{
            display: none;
        }
        &:hover{
            background-color: $c-white; color: $c-midnight-blue;
        }
    }

    &.getintouch{
        font-family:$primary-font; color:$c-dark-grayish; padding: 11px 22px; background-color: $c-white; font-weight: $weight-700; margin: 0 25px;
        &::after, &::before{
            display: none;
        }
        &:hover{
            background-color: $c-dark-grayish; color: $c-white;
        }
    }

    &.btn-blue-eyed{
        background-color: $c-pacific-blue2; color: $c-white; padding: 10px 14px; font-weight: $weight-800;
        &::after, &::before{
            display: none;
        }
        &:hover{
            background-color: $c-red2; 
        }
    }

    &.hero-btn{
        background-color: $c-white; color: $c-midnight-blue; padding: 10px 20px; font-weight: $weight-600; display: inline-block; line-height: 20px; font-size: 20px; z-index: 5;
        &::after, &::before{
            display: none;
        }
        &:hover{
            background-color: $c-midnight-blue; color: $c-white;
        }
        @media (max-width: 991.98px) {
            padding: 8px 18px; font-size: $f-18;
        }
    }
 }
 .fontchange{
    font-family: $secondary-font;
 }
 
 .page_heading{
     display: inline-block;    
     padding: 16px 0px 16px 0px;   
     width: 100%;
     background-image: url("../images/Header-bg.png");
     background-size: 100%;
     background-position: left 42%;
     background-repeat: no-repeat;    
     color: $c-white;
     background-size: cover;
     @media (max-width: 991.98px) {
        // padding: 20px 0px;
    }
     h1{ 
         display: inline-block;
         margin-bottom: 0;    
         padding-right: 12px; 
         font-size: 28px;
         line-height: 1.2;   
     }
     p{
         display: inline-block;
         font-size: $h3;
         font-weight: $weight-400; 
         line-height: 1.2;
         margin-bottom: 0;   
        @media (max-width: 991.98px) {
            // font-size: $f-18;
        }
     }
     span{        
         font-family: $secondary-font;
         font-size: 48px;
         padding-left: 5px;
         line-height: 1;
         @media (max-width: 991.98px) {
                // font-size: 50px;
            }
     } 
 }
 .breadcrumb{
     ul{
         list-style: none;
     }
     li{
         display: inline-block;
         font-size: 16px;
         font-style: italic;
         font-weight: $weight-500;
         padding-right: 10px; 
         margin-right: 10px;
        @media (max-width: 991.98px) {
            line-height: 25px;
        }
     }
     li:first-child{
         padding-left: 0;
     }
     li:not(:last-child)::after{
         content: "/";
         position: absolute;
         padding-left: 5px;
     }    
 }
 .section-space{
     padding: 100px 0;
     @media (max-width: 991.98px) {
         padding: 80px 0;
     }
     @media (max-width: 575.98px) {
         padding: 50px 0;
     }
 }
 .slick-slider{
     .slick-arrow{
         background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27px" height="38px"><image x="0px" y="0px" width="27px" height="38px" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAmCAMAAAAC7hAUAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA+VBMVEVYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVtYWVv////D8AfgAAAAUXRSTlMAODBhcK6HJv03b8XBcjQpgLMG00LxG5KhEeRQU+APpI8c80FjzwS1fScxIIajSdc8nEjrGAnYXIWtOvokx2x0vyoyftHykOLhUpGiQ9BVlWZBgfudAAAAAWJLR0RSDWAtkAAAAAd0SU1FB+cJCxAvMDVnz1sAAAC6SURBVDjLddHZWkEBGEbhXTRp0laK2IaECg0KiTSRJlr3fzO6gNZ3+j7rP/mDwLa0rBQjbrTCqsjaOhtCCTbt3hbbIjuwK5RkTyRMERPa5yAtdMiRSCbLsVCOfCRUoChSKnMiVOG0KlSjLnIG5//LRYOmREHrkqtA1+Da8Yb2reJdh66nPe4d+zwMXIc8Oo54cnyGF9car44V3kLF0lgf+bcmE8cc75FiNOXD00++HL8ZpxXDH2aezn8XUQUawoE2Y4EAAAAASUVORK5CYII=" /></svg>') !important;
         width: 27px; height: 38px; display: block; transform: inherit; margin-top: -19px;
         @media (max-width: 991.98px) {
            width: 18px;
         }
         @media (max-width: 767.98px) {
            width: 16px;
         }
 
         &:before, &::after{
             display: none;
         }
         &.slick-prev{
            left: -55px;
            @media (max-width: 991.98px) {
                left: -20px;
            }
         }
         &.slick-next{
            right: -55px; transform: rotate(180deg);
            @media (max-width: 991.98px) {
                right: -20px;
            }
         }
     }
 }
.cms{
    &.spin-wrap{
        padding: 90px 0 20px 0;

        @media (max-width: 1399.98px) {
            padding: 90px 70px 20px 70px
        }
        @media (max-width: 991.98px) {
            padding: 80px 45px 20px 45px
        }
        @media (max-width: 575.98px) {
            padding: 50px 20px 20px 20px
        }
    }
    h3{
        color: #404041; font-weight: $weight-400;
    }
    .description{
        color: $c-midnight-blue;
    }
}
.gradiant-bg{
    padding: 90px 0px 60px 0; width: 100%; background-image: url("../images/bg-image.svg"); background-repeat: no-repeat; background-size: cover;
     @media (max-width: 991.98px) {
          padding: 70px 0;
     }
     @media (max-width: 575.98px) {
          padding: 50px 0;
     }
}
.banner-bg{
      width: 100%; height: 100%; background-image: url(../images/Events-bg-img.png); background-repeat: no-repeat; background-size: cover; 
     @media (max-width: 991.98px) {
          padding: 70px 0;
     }
     @media (max-width: 575.98px) {
          padding: 50px 0;
     }
}

.align-left{
    text-align: left;
}


.button.btn-pacific-blue {
    color: #00356f;
    padding: 11px 22px;
    background-color: #85bbe4;
    font-weight: 700;
    font-size: 25px;
    line-height: 20px;
}

.button.btn-pacific-blue:hover {
    background-color: #ffffff;
    color: #00356f;
}

.button.btn-pacific-blue:after,.button.btn-pacific-blue-btn:before{
    content: none;
 }

.f-heding a.link-action {
    color: #ffffff;
}


.dell-dwnld-details{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    padding: 10px 0;
    a{
        line-height: 21px;
        display: flex;
        text-align: center;
        img {
            height: 17px;
            width: 17px;
            object-fit: cover;
            margin-left: 10px;
            border: 0px;
            padding: 0px;
        }
    }
}

// new responsive css 
@media (max-width: 767.98px) {
    .page_heading {
        padding: 8px 0px;
        height: 80px;
        display: flex;
        align-items: center;
        h1{
            font-size: 18px;
            line-height: 1.2;
        }
        p{
            font-size: 14px;
            line-height: 40px;
        }
        span{
            font-size: 36px;
        }
    }
}