// .responsive_heading{
//      @media (max-width: 991.98px) {
//           h1{
//                font-size: 26px !important;
//                line-height: 32px;
//                padding-right: 10px;
//           }
//      }
//      @media (max-width: 767.98px) {
//           padding: 17px 0px !important;
//           h1{
//                font-size: 22px !important;
//                line-height: 32px;
//           }
//      }
// }
.securing{
     background-color: $c-pacific-blue2; text-align: center;
     h1{
          color: $c-white; max-width: 615px; margin: 0 auto; margin-bottom: 50px;
          @media (max-width: 991.98px) {
               margin-bottom: 40px;
          }
          @media (max-width: 767.98px) {
               margin-bottom: 30px;
          }
     }
     h2{
          color: $c-white; max-width: 640px; margin: 0 auto; margin-bottom: 50px;
          @media (max-width: 991.98px) {
               margin-bottom: 40px;
          }
          @media (max-width: 767.98px) {
               margin-bottom: 30px;
          }
     }
     h3{
          color: $c-white; max-width: 640px; margin: 0 auto; font-weight: $weight-400;
          @media (max-width: 991.98px) {
               margin-bottom: 40px;
          }
          @media (max-width: 767.98px) {
               margin-bottom: 30px;
          }
     }
     p{
          color: $c-white; max-width: 1012px; margin: 0 auto; margin-bottom: 12px; word-spacing: 1.2px; letter-spacing: 0.5px;
     }
     ul{
          text-align: left !important;
          color: $c-white; font-weight: $weight-600; max-width: 1200px;
          li{
               text-align: left; color: $c-white; word-spacing: 1.2px; letter-spacing: 0.5px;
          }
     }
}

.weoffer{
     background-color: $c-solitude;
     .weoffer-heading{
          color: #404041; margin-bottom: 50px; margin-top: 0px;
          @media (max-width: 991.98px) {
               margin-bottom: 40px; margin-top: 0px;
          }
          @media (max-width: 767.98px) {
               margin-bottom: 30px; margin-top: 0px;
          }
          
     }
     p{
          word-spacing: 1.2px; letter-spacing: 0.4px;
     }
     strong{
          color: #bc4946;   
     }
     .weoffer-heading{
          color: #404041; margin-bottom: 50px;
          @media (max-width: 767.98px) {
               margin-bottom: 30px;
          }
     }
     .weoffer-inner{
          .weoffer-inner-tab{
                    padding-bottom: 30px; margin-bottom: 55px;
                    @media (max-width: 1199.98px) {
                         overflow-x: auto; margin-bottom: 15px; padding-bottom: 20px;
                    }
               .tabs{
                    list-style: none; display: flex; flex-direction: row; justify-content: space-between; position: relative;
                    li{
                         padding: 0 40px 29px 0; text-align: left; position: relative; width: -webkit-fill-available; width: -moz-available;
                         @media (max-width: 991.98px) {
                               padding: 0 25px 15px 0; min-width: 190px;
                         }
                         &:after{
                              content: ""; width: 100%; height: 3px; background-color: $c-white; display: block; position: absolute; top: auto; bottom: 0; left: 0; 
                         }
                         &:last-child{
                              position: relative; padding-right: 0;
                              &:after{
                                   content: "";
                              }
                         }
                         .tab-btn{
                              font-size: 16px;
                               line-height: 1.5;
                                color: $c-navy-blue1;
                                 font-weight: 500;
                                  background-color: transparent;
                                   text-align: left;
                                    cursor: pointer;
                                     font-family: $primary-font;
                              @media (max-width: 991.98px) {
                                   font-size: 20px; line-height: 28px;
                              }
                              &:after{
                                   transition: all 0.5s ease-in-out; content: ""; width: 0; height: 6px; background-color: $c-pacific-blue2; display: block; position: absolute; top: auto; bottom: 1px; left: 0; z-index: 9; 
                              }

                              &.active{
                                   &:after{
                                        width: 53px;
                                   }
                              }
                         }
                    }
                    .tab-itme-bglight{
                         &:after{
                              content: ""; width: 100%; height: 3px; background-color: $c-dark-grayish1; display: block; position: absolute; top: auto; bottom: 0; left: 0; 
                         }
                    }
               }
               // .shortest-tabs{
               //      li{
               //           position: relative;
               //           &:after{
               //                content: ""; width: 500%;
               //           }
               //           &:last-child{
               //                position: relative;
               //                &:after{
               //                     content: ""; width: 100%;
               //                }
               //           }
               //      }
               // }
               // .short-tabs{
               //      li{
               //           position: relative;
               //           &:after{
               //                content: ""; width: 170%;
               //           }
               //           &:last-child{
               //                position: relative;
               //                &:after{
               //                     content: ""; width: 100%;
               //                }
               //           }
               //      }
               // }
               // .mid-tabs{
               //      li{
               //           position: relative;
               //           &:after{
               //                content: ""; width: 105%;
               //           }
               //           &:last-child{
               //                position: relative;
               //                &:after{
               //                     content: ""; width: 100%;
               //                }
               //           }
               //      }
               // }
          }
          .weoffer-details{
               .tab-content{

                    .sec-row{
                         @media (max-width: 991.98px) {
                              flex-direction: column; justify-content: space-between;
                         }
                         .rgt-col{
                              padding-left: 0px; width: 50%; padding-right: 10px;
                              @media (max-width: 1024.98px) {
                                   width: 100%; margin-bottom: 20px; padding-right: 0;
                              }
                              @media (max-width: 767.98px) {
                                   margin-bottom: 0;
                              }
                              // img{
                              //      @media (max-width: 575.98px) {
                              //           margin: 0 auto; margin-bottom: 20px;
                              //      } 
                              // }
                         }
                         .lft-col{
                              padding-right: 0px; padding-left: 10px; width: 50%; display: flex; flex-direction: row; justify-content: flex-start; transition: all 1s ease-in-out;
                              @media (max-width: 1024.98px) {
                                   width: 100%; margin-bottom: 20px; padding-left: 0;
                              }
                              @media (max-width: 479.98px) {
                                   flex-direction:column;
                              }
                              .svg-col{
                                   img{
                                        margin-right: 20px; max-width: 100px; margin-left: 20px;
                                        @media (max-width: 1199.98px) {
                                             margin-left: 15px; margin-right: 15px;
                                        }
                                        @media (max-width: 1024.98px) {
                                             margin-left: 0px; margin-right: 20px;
                                        }
                                   }
                              }
                              .cntnt-col{
                                   transition: all 1s ease-in-out;
                                   .cntnt-heading{
                                        color: $c-navy-blue1;
                                        margin-bottom: 20px;
                                   }

                                   .cntnt-small-heading{
                                        color: $c-white; font-size: 25px; line-height: 34px; font-weight: 500; margin-bottom: 0px;
                                   }
                                   .cntnt-pra{
                                        line-height: 34px; word-spacing: 1.2px; letter-spacing: 0.5px;
                                   }
                                   .cntnt-head{
                                        color: #bc4946; font-size: 16px; line-height: 24px; margin-bottom: 16px; font-weight: 600;
                                   }
                              }
                         }
                    }
                    .sec-row-short{
                         .rgt-col-short{
                              width: 50%;
                              @media (max-width: 1024.98px) {
                                   width: 100%; margin-bottom: 20px;
                              }
                         }
                         .lft-col-short{
                              width: 42%;
                              @media (max-width: 1024.98px) {
                                   width: 100%; margin-bottom: 20px;
                              }
                              @media (max-width: 479.98px) {
                                   flex-direction:column;
                              }
                         }
                    }
               }
          }
     }
     .weoffer-point{
          .cntnt-heading{
               color: $c-navy-blue1; margin-bottom: 20px;
          }
          .weoffer-point-inner{
               display: flex; flex-direction: row; justify-content: flex-start;
               @media (max-width: 767.98px) {
                    flex-direction: column;
               }
               .point_inner{
                    width: 33.33%; margin-right: 100px;
                    @media (max-width: 991.98px) {
                         margin-right: 10px;
                    }
                    @media (max-width: 767.98px) {
                         width: 100%; margin-right: 00px; margin-bottom: 10px;
                    }
                    .cntnt-head{
                         color: #bc4946; font-size: 16px; line-height: 24px; margin-bottom: 16px; font-weight: 600;
                    }
                    p{
                         padding-top: 20px;
                         @media (max-width: 767.98px) {
                              padding-top: 0px;
                         }
                    }
               }
          }
     }
}
.weoffer-bglight{
     background-color: $c-white;
}
.weoffer-bgdark{
     .weoffer{
          background-color: $c-midnight-blue;
          .weoffer-heading{
               color: $c-white; margin-bottom: 50px;
               @media (max-width: 767.98px) {
                    margin-bottom: 30px;
               }
          }
          .weoffer-inner-tab{
               .tabs{
                    li{
                         color: $c-white;
                         .tab-btn{
                              color: $c-white;
                         }
                    }
               }
          }
          
          .tab-content{
               background-color: $c-midnight-blue;
          }
          .cntnt-col{
               h2{
                    color: $c-white;
               }
               div{
                    p{
                         color: $c-white;
                    }
               }
          }
          .button{
               background-color: $c-white; color: $c-dark-grayish; font-weight: $weight-600;
               &::after, &::before{
                   background-color: $c-dark-grayish;
               }
               &:hover{
                   background-color: $c-dark-grayish; color: $c-white;
                   &::after, &::before{
                       background-color: $c-white;
                   }
               }

               @media (max-width: 768px) {
                    margin-top: 10px;
               }
           }


     }
}
.advantage{
     @media (max-width: 767.98px) {
          text-align: center;
     }
     h2{
          color: $c-midnight-blue; font-weight: $weight-600; margin-bottom: 40px;
          @media (max-width: 1399.98px) {
               padding: 0px 60px;
          }
          @media (max-width: 991.98px) {
               padding: 0px 50px;
          }
          @media (max-width: 767.98px) {
               padding: 0px 20px; text-align: center;
          }
     }
     .advantage-inner{
          display: flex; flex-direction: row; flex-wrap: wrap; justify-content: start;
          @media (max-width: 1399.98px) {
               padding: 0px 60px;
          }
          @media (max-width: 991.98px) {
               padding: 0px 45px;
          }
          @media (max-width: 767.98px) {
               padding: 0px 30px;
          }
          @media (max-width: 575.98px) {
               padding: 0px 20px; justify-content: center;
          }
          @media (max-width: 480.98px) {
               padding: 0px 10px; 
          }
          .advantage-row{
               width: 33.33%;
               @media (max-width: 991.98px) {
                    width: 50%;
               }
               @media (max-width:767.98px) {
                    width: 100%;
               }
               .advantage-rowinner{
                    margin-bottom: 35px;
                    @media (max-width: 767.98px) {
                         margin-bottom: 40px;
                    }
                    @media (max-width: 767.98px) {
                         text-align: center;
                    }
                    img{
                         max-height:60px; height:100%; object-fit: cover; width: auto;
                         @media (max-width: 767.98px) {
                              margin: 0 auto;
                         }   
                    }
                    h4{
                         color: $c-midnight-blue; font-weight: 600; margin: 10px 70px 10px 0;
                         @media (max-width: 1399.98px) {
                              margin-right: 40px;
                         }
                         @media (max-width: 991.98px) {
                              margin-right: 10px;
                         }
                         @media (max-width: 575.98px) {
                              margin-right: 0px; 
                         }
                    }
                    p{
                         line-height: 28px; color: $c-midnight-blue; margin-right: 70px; font-weight: $weight-500; word-spacing: 1.2px; letter-spacing: 0.5px;
                         @media (max-width: 1399.98px) {
                              margin-right: 40px;
                         }
                         @media (max-width: 1199.98px) {
                              margin-right: 20px;
                         }
                         @media (max-width: 991.98px) {
                              line-height: 28px; margin-right: 15px;
                         }
                         @media (max-width: 767.98px) {
                              margin-right: 0px; 
                         }
                    }
               }
          }
     }
     a{
          
          @media (max-width: 1399.98px) {
               padding: 0px 60px;
          }
          @media (max-width: 991.98px) {
               padding: 0px 50px;
          }
          @media (max-width: 767.98px) {
               padding: 0px 60px;
          }
          @media (max-width: 575.98px) {
               padding: 0px 30px; justify-content: center;
          }
          @media (max-width: 480.98px) {
               padding: 0px 20px; 
          }
     }
}



.supercharge{
     background-color: $c-navy-blue2; padding: 40px 0px 45px 0;
     @media (max-width: 767.98px) {
          padding: 30px 0px;
     }
     .supercharge-heading{
          color: $c-white; text-align: center; margin-bottom: 30px;
          @media (max-width: 991.98px) {
               font-size: 26px; line-height: 34px;
          }
          @media (max-width: 767.98px) {
               margin-bottom: 10px;
          }
     }
    
     .supercharge-btns{
          text-align: center;
          .getintouch{
               color:$c-dark-grayish; padding: 11px 22px; background-color: $c-white; border-radius: 100px; font-weight: 600; font-size: 16px; margin:0px 25px; transition: all ease-in-out 0.5s; cursor: pointer;
               @media (max-width: 767.98px) {
                    padding: 8px 15px; margin: 15px 15px 0px 15px;
               }
               @media (max-width: 575.98px) {
                    margin: 10px;
               }
               &:hover{
                    background-color: $c-dark-grayish; color: $c-white;
               }
          }
     }
}

.supercharge-left{
     .supercharge-heading{
          text-align: left;
     }
     .supercharge-btns{
          text-align: left;
          .getintouch{
               margin-left: 0px; font-family:$primary-font;
          }
     }
}

.esteemed-partners{
     background-color: $c-solitude;
     h2{
          margin-bottom: 50px;
     }
     .esteemed-icon{
          list-style: none; margin: 0 -20px;
          @media (max-width: 1199.98px) {
               margin: 0 -15px;
          }
          li{
               width: 20%; padding: 30px 40px; display: inline-block; vertical-align: middle;
               @media (max-width: 1199.98px) {
                    padding: 30px;
               }
               @media (max-width: 991.98px) {
                    width: 25%;
               }
               @media (max-width: 767.98px) {
                    width: 33.33%;
               }
               @media (max-width: 575.98px) {
                    width: 50%; padding: 22px 35px;
               }
               img{
                    max-height: 47px;
               }
          }
     }
}
.esteemed-partners-bglight{
     background-color: $c-white;
}

.our-capabilities{
     position: relative; width: 100%; min-height: 750px; background-repeat: no-repeat; background-size: cover; background-position: bottom;
     background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 753" style="enable-background:new 0 0 1440 753;" xml:space="preserve"><style type="text/css">.st0{fill:%23E3E4E5;}</style><g><path class="st0" d="M0.1,490.5c0-162.1,0-324.2-0.1-486.3c0-3.6,0.5-4.7,4.5-4.7c477,0.1,954,0.1,1431,0c4,0,4.5,1.1,4.5,4.7c-0.1,162.4-0.1,324.8-0.1,487.3c-3.6,7.2-10,12-15.6,17.3c-17.8,16.8-36.2,33-55.5,48.1c-34.1,26.6-70.2,50.2-108,71c-50.4,27.7-103.1,50-157.6,68c-64.3,21.2-130.1,35.7-197.1,45.1c-27.1,3.8-54.2,6.4-81.4,8.8c-10.4,0.9-20.9,1.3-31.4,1.4c-14.5,0.2-29,1-43.5,0.6c-19.8,0-39.5,0-59.3,0c-20.8,0.2-41.6-0.1-62.4-1.2c-28.6-1.4-57.1-4.4-85.5-8c-51.3-6.5-102-16.2-152-29.5c-50.3-13.3-99.4-30.4-147-51.7c-41.8-18.6-81.8-40.5-119.9-65.8c-32.1-21.3-62.4-45-90.6-71.3c-9.4-8.7-18.3-17.9-27.6-26.8C3.2,495.6,1.4,493.2,0.1,490.5z"/></g></svg>'); 
    
     @media (max-width: 1199.98px) {
          // border-bottom-left-radius: 100px; border-bottom-right-radius: 100px; 
          min-height: auto;
          &::after{
               content: ''; background: #e3e4e5; height: 70%; width: 100%; position: absolute; top: 0; left: 0; z-index: -1;
          }
     }
     @media (max-width: 991.98px) {
          min-height: 650px; background-size: contain;
     }
     @media (max-width: 767.98px) {
          min-height: 590px;
          &::after{
               height: 80%;
          }
     }
     @media (max-width: 575.98px) {
          min-height: 500px;
          &::after{
               height: 92%;
          }
     }
     h2{
          text-align: center; margin-bottom: 50px;
          @media (max-width: 575.98px) {
               margin-bottom: 10px;
          }
     }
     .capabilities-inner{
          text-align: center;
          .capabil-frow{
               display: flex; flex-wrap: wrap; flex-direction: row;
               @media (max-width: 991.98px) {
                    border-bottom: 2px solid $c-white; padding-bottom: 20px; margin-bottom: 20px;
               }
               @media (max-width: 575.98px) {
                    flex-direction: column; border-bottom: none; padding-bottom: 0px; margin-bottom: 0px;
               }
               h4{
                    width: 32.5%; color: $c-midnight-blue; text-align: center; padding: 40px 50px 40px 50px; font-weight: 500; font-size: 25px; margin-bottom: 0px;
                    @media (max-width: 991.98px) {
                         padding: 20px 15px; font-size: 20px; line-height: 28px;
                    }
                    @media (max-width: 575.98px) {
                         width: 100%; border-bottom: 2px solid $c-white; border-right: none; padding: 15px 0px;
                    }
               }
          }
          .capabil-srow{
               display: flex; flex-wrap: wrap; flex-direction: row; align-items: center;
               @media (max-width: 575.98px) {
                   flex-direction: column;
               }
               h4{
                    width: 49.5%; text-align: center; color: $c-midnight-blue; font-weight: 500; font-size: 25px; padding: 40px 85px 40px 85px; margin-bottom: 0px;
                    @media (max-width: 991.98px) {
                         padding: 20px 30px; font-size: 20px; line-height: 28px;
                    }
                    @media (max-width: 767.98px) {
                         padding: 20px 15px; 
                    }
                    @media (max-width: 575.98px) {
                         width: 100%; margin-bottom: 0px; border-bottom: 2px solid $c-white; border-right: none;  
                         padding: 15px 0px;
                    }
                    &:last-child{
                         border-bottom:none;
                    }
                    
               }
          }
          .iconborderbottom{
               @media (max-width: 991.98px) {
                    display: none;
               }
               img{
                    @media (max-width: 991.98px) {
                         display: none;
                    }
               }
          }
          .iconborder{
               @media (max-width: 575.98px) {
                    display: none;
               }
          }
     }
}
.section-space-top{
     padding-top: 140px; padding-bottom: 0px;
          @media (max-width: 991.98px) {
               padding: 80px 0;
          }
          @media (max-width: 767.98px) {
               padding: 40px 0;
          }
}

.high-speed-bread{
     @media (max-width: 1399.98px) {
          padding: 0px 40px;
     }          
     @media (max-width: 1199.98px) {
          padding: 0px 30px;
     }          
     @media (max-width: 767.98px) {
          padding: 0px 17px;
     }
     @media (max-width: 575.98px) {
          padding: 0px 20px;
     }
     @media (max-width: 480.98px) {
           padding: 0px 10px;
     }
}
.empowering{
     .empowering-inner{
          display: flex; flex-wrap: nowrap; flex-direction: row;
          @media (max-width: 1399.98px) {
               padding: 0px 40px;
          }          
          @media (max-width: 1199.98px) {
               padding: 0px 30px;
          }          
          @media (max-width: 767.98px) {
               padding: 0px 17px;
          }
          @media (max-width: 575.98px) {
               flex-wrap: wrap; padding: 0px 20px;
          }
          @media (max-width: 480.98px) {
                padding: 0px 10px;
          }
          .empowering-left{
               margin-right: 23px;
               svg,img{
                    max-height: 60px; max-width: 60px;
               }
          }
          .empowering-right{
               h4{
                    color: $c-midnight-blue;  font-weight: $weight-500; font-size: 25px !important; line-height: 54px;
                    @media (max-width: 767.98px) {
                         font-size: 22px; line-height: 30px;
                    }
               }
               h3{
                    color: $c-midnight-blue;  font-weight: $weight-500; font-size: 25px !important; line-height: 54px;
                    @media (max-width: 767.98px) {
                         font-size: 22px; line-height: 30px;
                    }
               }
               h2{
                    color: #606060;
                    @media (max-width: 767.98px) {
                         font-size: 28px; line-height: 36px;
                    }
               }
               h3{
                    color: $c-midnight-blue;  font-weight: $weight-500; font-size: 25px; line-height: 54px;
               }
               ul{
                    li{
                         color: $c-dark-grayish; margin: 0 auto; margin-bottom: 12px; word-spacing: 1.2px; letter-spacing: 0.5px;  font-weight: $weight-500;
                    }
               }
               
          }
          .high-speed-information{
               h2,h3,h4{
                    color: #b6312d; max-width: 675px; margin-bottom: 35px;
                    @media (max-width: 767.98px) {
                         margin-bottom: 25px;
                    }
               }
               p{
                    color: $c-midnight-blue; font-weight: $weight-600;
               }
          }
     }
}

.frequently{
     background-color: #85bbe4;
}

// High-Speed Broadband - Ishan Offer Section START
.ishan-offer{
     h2{
        margin-bottom: 50px; color: $c-white; text-align: center;
          @media (max-width: 1199.98px) {
               br{
                    display: none;
               }
          }
          @media (max-width: 575.98px) {
               margin-bottom: 30px;
          }
     }
     .offer-btns{
          text-align: center;
          .button{
               margin: 0 25px; position: relative; z-index: 9;
               @media (max-width: 575.98px) {
                    margin: 0 auto; display: block; width: fit-content;
               }
          }
          .availability-wrap{
               display: inline-block; position: relative;
               @media (max-width: 767.98px) {
                    margin-top: 20px;
               }
               .input-value{
                    position: absolute; top: 0; left: 50%; transform: translateX(-50.01%); z-index: 0; width: calc(100% - 52px); -webkit-box-shadow: 0px 2px 14px 2px rgba(0,0,0,0.12); -moz-box-shadow: 0px 2px 14px 2px rgba(0,0,0,0.12); box-shadow: 0px 2px 14px 2px rgba(0,0,0,0.12); border-radius: 20px; overflow: hidden;
                    @media (max-width: 575.98px) {
                         width: 100%; font-size: $f-20;
                    }
                    input{
                         color: $c-midnight-blue;
                    }
                    .input-available{
                         border-radius: 20px; cursor: pointer; height: 42px; padding: 11px 23px; transition: all 0.3s ease-in-out; width: 100%; background-color: #e6e7e8; color: $c-midnight-blue; font-size: $f-25; font-weight: $weight-700; background-repeat: no-repeat; background-size: 22px; background-position: 90% 82%; 
                         background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="21px"><svg xmlns="http://www.w3.org/2000/svg" fill="%2300a0e7" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 24 24"><path fill-rule="nonzero" d="M15.97 17.031a8.482 8.482 0 0 1-5.461 1.985C5.812 19.016 2 15.204 2 10.508S5.812 2 10.509 2c4.695 0 8.508 3.812 8.508 8.508a8.476 8.476 0 0 1-1.985 5.461l4.749 4.75a.748.748 0 0 1 .219.531c0 .587-.537.75-.75.75a.748.748 0 0 1-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z"></path></svg></svg>');
                         
                         @media (max-width: 575.98px) {
                              font-size: $f-20;
                         }
                    }
                    ::-ms-input-placeholder {
                         color: $c-midnight-blue;
                    }   
                    ::placeholder {
                         color: $c-midnight-blue;
                    }
                    .availability-status{
                         margin-top: -60px; opacity: 0; text-align: left; display: block; padding: 27px 23px 10px; transition: all 0.3s ease-in-out; width: 100%; background-color: $c-white; color: $c-midnight-blue; font-size: $f-25; font-weight: $weight-700; position: relative; z-index: -1;
                         
                         @media (max-width: 575.98px) {
                              font-size: $f-20;
                         }
                    }
                    .fade-in {
                         margin-top: -20px; transition: all 0.4s ease-in-out; opacity: 1;
                    }
                    &.opened{
                         .input-available{
                              padding: 65px 23px 23px;
                         }
                    }
               }
          }
     }      
}
// High-Speed Broadband - Ishan Offer Section END

// Accordion CSS START
.accordion {
     width: 100%; margin-top: 78px;
     @media (max-width: 991.98px) {
          margin-top: 40px;
     }
     @media (max-width: 575.98px) {
          margin-top: 30px;
     }
     .accordion-item {
          margin-bottom: 10px;

          &:last-child{
               .accordion-content{
                    margin-bottom: 0; background-color: #fff !important;
               }
          }
          .accordion-title {
               width: 100%; padding: 20px 50px 20px 20px; text-align: left; font-size: $f-16; color: $c-midnight-blue; display: block; cursor: pointer; background-color: $c-white; position: relative; font-family: $rubik-font; font-weight: $weight-600; position: relative; z-index: 9;
               .expand-bar{
                    width: 13px; height: 3px; background-color: $c-midnight-blue;position: absolute; top: 50%; left: auto; right: 20px; transition: all 0.5s ease-in-out;
                    &::after{
                         content: ""; width: 13px; height: 3px; background-color: $c-midnight-blue; position: absolute; left: 0; top: 0; transform: rotate(90deg); transition: all 0.5s ease;
                    }
               }
               &.active{
                    .expand-bar{
                         transform: rotate(180deg);
                         &::after{
                              transform: rotate(180deg);
                         }
                    }
               }
          }
          .accordion-content {
               padding-left: 20px; padding-right: 20px; opacity: 0; max-height: 0; overflow: hidden; transition: all 0.7s ease-in-out;
               &.active{
                    padding: 20px; padding-top: 0; opacity: 1; max-height: 1000px; margin-top: 0; background-color: $c-white;
                    // @media (max-width: 991.98px) {
                    //      padding-top: 20px;
                    // }
               }
               p{
                    color: $c-midnight-blue;
                    &:last-child{
                         margin-bottom: 0;
                    }
                    a{
                         color: $c-pacific-blue2; font-weight: $weight-600;

                         &:hover{
                              color: $c-red1;
                         }
                    }
               }
               ul{
                    margin-left: 18px;
                    li{
                         color: $c-midnight-blue; list-style-position: outside; 
                    }
               } 
          }
     }   
}

// responsive accordion for colocation start
.mt-4{
     margin-top: 24px;
}
.for-mobile{
     .accordion{
          .accordion-item{
               .accordion-title{
                    display: block;
                    cursor: pointer;
                    background-color: transparent;
                    border-bottom: 1px solid #5c5c5c66;
                    font-size: 20px;
                    font-weight: 400;
                    color: #000;
                    .chev-ic{
                         position: absolute;
                         right: 8px;
                         color: #fff;
                         top: 28px;
                         transform: rotate(0deg);
                         transition: all 0.5s ease;
                    }
                    &.active{
                         .chev-ic{
                              transform: rotate(180deg);
                         }
                    }
               }
               .accordion-content {
                    transition: all 0.3s ease-in-out;
                    &.active{
                         background-color: unset !important;
                         padding: 20px;
                         max-height: 100%;
                         // border: 1px solid #dddddd;
                         border-top: 0px ;

                    }
                    p{
                         color: #000;
                    }
                    .accordion-image{
                         display: inline-block;
                    }
               }
               
          }
          
     }  
}
.weoffer-bgdark{
     .for-mobile{
          .accordion{
               .accordion-item{
                    .accordion-title{
                         color: #fff;
                         background-color: #00356f;
                         border-bottom: 1px solid #dbdbdb57;
                         
                         .expand-bar{
                              background-color: #fff;
                              &::after{
                                   background-color: #fff;
                              }
                         }
                         .chev-ic{
                              filter: invert(1);
                         }
                    }
                    .accordion-content {
                         color: #fff;
                         &.active{
                              background-color: unset !important;
                         }
                         p{
                              color: #fff;
                         }
                         .accordion-image{
                              display: inline-block;
                         }
                         ul li{
                              color: #fff;
                         }
                         h3{
                              color: #fff;
                         }
                    }
               }
               
          }  
     }
} 
 @media (min-width: 1200px) {
      .weoffer {
     .for-mobile{
          display: none;
     }
     }
 }
 
 @media (max-width: 1200px) {
     .weoffer {
     .for-mobile{
          display: block;
          .accordion .accordion-item {
               margin-bottom: 0px;
           }
     }
     .for-desk{
          display: none;
     }
     }
 }
 @media (max-width: 767.98px) {
     .for-mobile .accordion .accordion-item .accordion-title{
          font-size: 16px;
          padding: 24px 48px 24px 4px;
          line-height: 1.3;
          .expand-bar{
               top: 32px;
               right: 8px;
          }
     }
     .for-mobile .accordion .accordion-item .accordion-content.active {
          padding: 20px 0px;
      }
 }
// responsive accordion for colocation End


// Accordion CSS END

// Co-location page map section Start


.sec-ishan-map{
     padding-top: 50px; padding-bottom: 0px;
}
.ishan-map{
     background-color: #009bdc; position: relative;
     .indian-map{
          position: relative;
          img{
               height: auto;
          }
          .map-title{
               content: " "; position: absolute; top:15%; right: 9%;  
               @media (max-width: 1199.98px) {
                    top:15%; right: 9%; 
               }
               @media (max-width: 991.98px) {
                    top:15%; right: 7%; 
               }
               @media (max-width: 767.98px) {
                    top:10%; right: 9%; 
               }
               @media (max-width: 575.98px) {
                    top:9%; right: -3%; 
               }
               @media (max-width: 480.98px) {
                    top:3%; right: 0%; 
               }
               h4{
                   font-size: 40px; font-weight: 400; color: $c-white; margin: 0px;
                    @media (max-width: 1199.98px) {
                         font-size: 30px;
                    }
                    @media (max-width: 991.98px) {
                         font-size: 26px;
                    }
                    @media (max-width: 767.98px) {
                         font-size: 24px; max-width: 250px;
                    }
                    @media (max-width: 575.98px) {
                         font-size: 22px; line-height: 30px; padding: 0px; margin: 0px; margin-bottom: 10px; 
                    }
                    @media (max-width: 480.98px) {
                         font-size: 18px; line-height: 25px; max-width: 150px;
                    }
               }
         }
         .map-info{
               content: " "; position: absolute; top: 59%; right: 13%;  
                    @media (max-width: 1199.98px) {
                         top: 70%; right: 13%;  
                    }
                    @media (max-width: 991.98px) {
                         top: 70%; right: 10%;  
                    }
                    @media (max-width:767.98px) {
                         top:70%; right: 5%;   
                    }
                    @media (max-width:480.98px) {
                         top:70%; right: -3%;   
                    }
                    
               .map-info-inner{
                    display: flex; flex-direction: row; flex-wrap: wrap; align-items: top; margin-bottom: 15px; 
                         @media (max-width: 1199.98px) {
                              font-size: 30px;
                         }
                         @media (max-width: 991.98px) {
                              font-size: 26px;
                         }
                         @media (max-width: 767.98px) {
                              margin-bottom: 10px; 
                         }
                         @media (max-width: 480.98px) {
                              margin-bottom: 5px; 
                         }
                         
                    img{
                         max-width: 50px; max-height: 50px;
                              @media (max-width: 1199.98px) {
                                   max-width: 35px; max-height: 35px;
                              }
                              @media (max-width: 991.98px) {
                                   max-width: 30px; max-height: 30px;
                              }
                              @media (max-width: 767.98px) {
                                   max-width: 25px; max-height: 25px;
                              }
                              @media (max-width: 480.98px) {
                                   max-width: 20px; max-height: 20px;
                              }
                    }
                    h3{
                         margin: 0px; margin-left: 10px; color: $c-white; max-width: 275px; line-height: 50px;
                              @media (max-width: 1199.98px) {
                                   font-size: 20px; line-height: 35px; max-width: 225px; 
                              }
                              @media (max-width: 991.98px) {
                                   font-size: 18px; line-height: 30px;
                              }
                              @media (max-width: 767.98px) {
                                   font-size: 16px; line-height: 25px; max-width: 150px; margin-left: 10px;
                              }
                              @media (max-width: 480.98px) {
                                   font-size: 13px; margin-left: 5px; max-width: 120px;
                              }
                    }
               }
         }
     }
     .indian-places{
          margin-top: -30px;
          @media (max-width: 767.98px) {
               margin-top: 0px;
          }
          @media (max-width: 480.98px) {
               margin-top: 25px;
          }
          img{
               height: auto; width: auto;
          }
     }
}

// Co-location page map section End


.form-error-msg{
     display: block; padding: 10px 0 0 0px;
     .errorMsg{
          color: #b52337;
          font-size: 16px;
          font-weight: 600;
          
     }
}
.form-text-center{
     text-align: center;
     .errorMsg{
          margin-bottom: 20px !important;
     }
}
.onlyform-text-center{
     text-align: center;
}