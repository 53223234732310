.our-team{
     p.breadcrumb{
          font-size: $p; font-weight: 500; color: $c-dark-grayish;
     }
}
.sml-section-space{
     padding: 60px 0px 0px 0px;
     @media (max-width: 575.98px) {
         padding: 40px 0px 0px 0px;
     }
}
.our-team{
     .sec-our-team.section-space{
          padding: 100px 0;
          @media (max-width: 991.98px) {
               padding: 30px 0px 60px 0px;
          }
          @media (max-width: 767.98px) {
               padding: 20px 0px 60px 0px;
          }
     }
     .sec-our-team{
          h2{
               color: $c-dark-grayish; margin-bottom: 50px;
                    @media (max-width: 991.98px) {
                         margin-bottom: 25px;
                    }
          }
          p{
               color: $c-navy-blue2; font-weight: $weight-600; margin-bottom: 50px;
               @media (max-width: 767.98px) {
                    margin-bottom: 25px;
                }
          }
          .our-points{
               display: flex; flex-direction: row; justify-content: space-between; 
               @media (max-width: 767.98px) {
                    flex-direction: column;
               }
               .our-points-left{
                    padding-left: 0px; width: 50%; padding-right: 60px;
                    @media (max-width: 991.98px) {
                          padding-right: 20px;
                    }
                    @media (max-width: 767.98px) {
                         width: 100%; padding-right: 0px;
                    }
                    img{
                         max-height: 308px; height: auto;
                         @media (max-width: 575.98px) {
                              margin: 0 auto; margin-bottom: 20px;
                         } 
                    }
               }
               .our-points-right{
                    width: 50%;  padding-left: 60px;
                    @media (max-width: 991.98px) {
                         padding-left: 20px;
                    }
                    @media (max-width: 767.98px) {
                         width: 100%;  padding-left: 0px;
                    }
                    ul{
                         list-style-position: outside; margin-left: 18px;
                         @media (max-width: 767.98px) {
                              margin-top: 20px;
                         }
                         li{
                              font-size: $p; font-weight: 500; color: $c-dark-grayish; margin-bottom: 25px;
                              @media (max-width: 991.98px) {
                                   width: 100%; margin-bottom: 10px;
                              }
                         }
                    }
               }
          }
          
     }
     .sec-our-culture{
          background-color: #85bbe4;
          h2{
               color: $c-white; margin-bottom: 50px;
               @media (max-width: 991.98px) {
                    margin-bottom: 25px;
               }
          }
          p{
               color: $c-navy-blue2; font-weight: $weight-600; margin-bottom: 50px;
               @media (max-width: 767.98px) {
                    margin-bottom: 25px;
               }
          }
          .our-points{
               display: flex; flex-direction: row; justify-content: space-between; 
               @media (max-width: 767.98px) {
                    flex-direction: column; justify-content: space-between;
               }
               .our-points-left{
                     width: 50%; padding-left: 30px;  
                    @media (max-width: 991.98px) {
                         padding-left: 10px; 
                    }
                    @media (max-width: 767.98px) {
                         width: 100%; padding-left: 0px; padding-top: 5px;
                    }
                    .our-points-left-inner{
                         display: flex; justify-content: center;
                         img{
                              max-height: 308px; height: auto;
                              @media (max-width: 575.98px) {
                                   margin: 0 auto; margin-bottom: 20px;
                              } 
                         }
                    }
                    .slick-slider{
                         .slick-list{
                              .slick-dots{
                                   li{
                                        margin: 0px;
                                        button{
                                             &::before{
                                                  color: #fff !important; opacity: 1;
                                             }
                                        }
                                   }
                              }
                              
                         }
                    }
                    .slick-dots li button:before {
                         color: #fff ; opacity: 1; font-size: 10px;
                    }
                    li.slick-active button:before {
                         opacity: 1; color: #003673;
                    }
                    .slick-dots{
                         bottom: -34px; padding-left: 31px;
                         @media (max-width: 767.98px) {
                              padding-left:00px; bottom: -30px;
                         }
                         @media (max-width: 575.98px) {
                              padding-left:00px; bottom: -25px;
                         }
                    }
               }
               .our-points-right{
                    width: 50%;  padding-right: 60px;
                    @media (max-width: 991.98px) {
                         padding-right: 20px;
                    }
                    @media (max-width: 767.98px) {
                         width: 100%;  padding-right: 0px;
                    }
                    ul{
                         list-style-position: outside; margin-left: 18px;
                         li{
                              font-size: $p; margin-bottom: 25px; color: $c-navy-blue2; font-weight: $weight-600;
                              @media (max-width: 991.98px) {
                                   width: 100%; margin-bottom: 10px;
                              }
                         }
                    }
               }
          }
     }

     .careers-join-us{
          background-color: $c-navy-blue2; text-align: left;
          p{
               color: $c-white;
          }
          .supercharge-btns{
               button{
                    padding: 11px 22px; font-size: 25px; font-weight: 700; margin-bottom: 20px; cursor: pointer;
                    @media (max-width: 575.98px) {
                         margin: 10px 10px 10px 0; font-size: 20px;
                    }
               }
          }
     }

     .our-values{
          background-color: $c-solitude2;
          h2{
               color: $c-dark-grayish; margin-bottom: 50px;
               @media (max-width: 991.98px) {
                    margin-bottom: 25px;
               }
          }
          img{
               max-height:300px; height: auto; width: auto;
          }
          p{
               color: $c-navy-blue2; font-weight: $weight-600; padding: 30px 0px 12px 0px; 
          }
          ul{
               list-style-position: outside; margin-left: 18px;
               li{
                    color: $c-navy-blue2; font-weight: $weight-600;
               }
          }
     }
     .life-at-ishan{
          h2{
               margin-bottom: 50px; text-align: center;
               @media (max-width: 991.98px) {
                    margin-bottom: 25px;
               }
          }
          .ishantec{
                margin:0px -30px ;
                    @media (max-width: 1399.98px) {
                         margin: 0px -10px;
                    }
                    @media (max-width: 1199.98px) {
                         margin: 0px -20px;
                    }
               .ishantec-inner{
                    padding: 0px 30px;
                    @media (max-width: 1399.98px) {
                         padding: 0px 20px;
                    }
                    @media (max-width: 1199.98px) {
                         padding: 0px 20px;
                    }
                    .inner-img{
                         display: flex; align-items: center; justify-content: center; max-height: 290px;
                         img{
                              min-height: 290px; max-height: 290px; height: 100%; object-fit: cover;
                         }
                    }
                    .inner-text{
                         text-align: center;
                         h3{
                              margin-top: 50px; line-height: 1.4;
                              @media (max-width: 1399.98px) {
                                   margin-top: 35px; 
                              }
                              @media (max-width: 1199.98px) {
                                   margin-top: 25px; 
                              }
                              @media (max-width: 767.98px) {
                                   margin-top: 20px; 
                              }
                         }
                         img{
                              margin: 50px auto 0 auto;
                              @media (max-width: 1399.98px) {
                                   margin-top: 35px; 
                              }
                              @media (max-width: 1199.98px) {
                                   margin-top: 25px; 
                              }
                              @media (max-width: 767.98px) {
                                   margin-top: 20px; 
                              }
                         }
                    }
               }
               .slick-slider .slick-prev{
                    top: 37%;
                    @media (max-width: 1399.98px) {
                         left: -20px; width: 17px;
                    }
                    @media (max-width: 1299.98px) {
                         left: -5px; width: 17px;
                    }
               }
               .slick-slider .slick-next{
                    top: 37%;
                    @media (max-width: 1399.98px) {
                         right: -20px; width: 17px;
                    }
                    @media (max-width: 1299.98px) {
                         right: -5px; width: 17px;
                    }
               }
               li{
                    margin: 0px;
                    button{
                         &::before{
                              color: $c-navy-blue2 !important; opacity: 1; font-size: 10px;
                         }
                    }
               }
               li.slick-active button:before {
                    opacity: 1; color: #85bbe4 !important;
               }
               
          }
     }
}
.forcls{
     min-height: 144px;
}