.our-customer{
     .container{
          .title-heading{
                text-align:left; margin-bottom: 25px; font-weight: $weight-500;
               @media (max-width: 991.98px) {
                    font-size: 26px; line-height: 34px;
               }
               @media (max-width: 767.98px) {
                    margin-bottom: 10px;
               }
          }
          .title-para{
               color: $c-navy-blue2; text-align:left; font-weight: 600; line-height: 30px; margin-bottom: 0px; 
               font-size: $f-16; letter-spacing: 0.5px;
          }
     }

     // Accordion CSS START

     .accordion {
          width: 100%; margin-top: 30px;
               @media (max-width: 991.98px) {
                    margin-top: 40px;
               }
               @media (max-width: 575.98px) {
                    margin-top: 30px;
               }
     .accordion-item {
          margin-bottom: 35px;
          @media (max-width: 575.98px) {
               margin-bottom: 35px;
          }
          &:last-child{
               .accordion-content{
                    margin-bottom: 0;
               }
          }
          .accordion-title {
               width: 100%; padding: 10px; text-align: left; font-size: $f-18; color: $c-midnight-blue; display: block; cursor: pointer; background-color: $c-light-cyn-blue; position: relative; font-family: $rubik-font; font-weight: $weight-600; position: relative; z-index: 9; display: flex; flex-direction: row; text-align: center; align-items: center; 
               @media (max-width: 575.98px) {
                    font-size: $f-16;
               }
               img{
                    margin-right: 10px; max-height: 40px; width: 40px;
                    @media (max-width: 575.98px) {
                         max-height: 35px; width: 35px;
                    }
               }
               .expand-bar{
                    width: 13px; height: 3px; background-color: $c-midnight-blue;position: absolute; top: 50%; left: auto; right: 20px; transition: all 0.5s ease-in-out;
                    &::after{
                         content: ""; width: 13px; height: 3px; background-color: $c-midnight-blue; position: absolute; left: 0; top: 0; transform: rotate(90deg); transition: all 0.5s ease;
                    }
               }
               &.active{
                    .expand-bar{
                         transform: rotate(180deg);
                         &::after{
                              transform: rotate(180deg);
                         }
                    }
               }
          }
          .accordion-content {
               padding-left: 20px; padding-right: 20px; opacity: 0; max-height: 0; overflow: hidden; transition: all 0.7s ease-in-out; margin-top: -25px;
               &.active{
                    padding-top: 40px; padding-bottom: 5px; opacity: 1; max-height: 1000px; margin-top: 0;
                    @media (max-width: 991.98px) {
                         padding-top: 20px;
                    }
               }
               .esteemed-icon{
                    list-style: none; margin: 0 -20px;
                    @media (max-width: 1199.98px) {
                         margin: 0 -15px;
                    }
                    li{
                         width: 20%; padding: 20px 35px; display: inline-block; vertical-align: middle;
                         @media (max-width: 1199.98px) {
                              padding: 20px;
                         }
                         @media (max-width: 991.98px) {
                              width: 25%;
                         }
                         @media (max-width: 767.98px) {
                              width: 33.33%;
                         }
                         @media (max-width: 575.98px) {
                              width: 50%; padding: 20px 30px;
                         }
                         img{
                              max-height: 60px; width: auto; height: auto;
                         }
                    }
               }
          }
     }   
}
// Accordion CSS END

}
.md-section-space{
     padding: 80px 0;
     @media (max-width: 991.98px) {
         padding: 60px 0;
     }
     @media (max-width: 575.98px) {
         padding: 40px 0;
     }
}