.sec-staying-ahead{
     background-color: #85bbe4;
     h2{
          color: $c-white; margin-bottom: 40px;
          @media (max-width: 991.98px) {
               margin-bottom: 25px;
          }
     }
     p{
          color: $c-navy-blue2; font-weight: $weight-600; 
     }
}
.weoffer-white{
     background-color: transparent;
     
     .weoffer-inner .weoffer-inner-tab{
          margin-bottom: 40px;
          .tabs li:after {
               background-color: #5b5c5e;
          }
     }
     .blogname{
          display: flex; flex-direction: row;  margin: 0px -7px; flex-wrap: wrap;
          @media (max-width: 991.98px) {
               margin: 0px -17px;
          }
          .blog-inner{
               width: 33.33%; padding: 0px 17px; padding-bottom: 70px;
               @media (max-width: 991.98px) {
                    padding-bottom: 40px;
               }     
               @media (max-width: 991.98px) {
                    width: 50%; padding:0px 17px; padding-bottom: 30px;
               }     
               @media (max-width: 480.98px) {
                    width: 100%;  padding:0px 17px; padding-bottom: 20px;
               }     
               img{
                    max-height: 190px; min-height: 190px; width: 100%; object-fit: cover;
               }
               .blog-text{
                    padding: 25px 0px ;
                    @media (max-width: 1199.98px) {
                         padding: 20px 0px;
                    }
                    @media (max-width: 991.98px) {
                         padding: 15px 0px;
                    }
                    .action-link{
                         &:hover{
                              h5{
                                   color: $c-red1;
                              }
                         }
                    }
                    h5{
                         line-height: 25px; font-weight: 700; font-size: 17px; margin-bottom: 10px;
                    }
                    P{
                         line-height: 25px; font-size: 16px; color: #5e5e5e; font-weight: 600;
                    }
                    &.list-summery{
                         div{
                              *{
                                   line-height: 25px; font-size: 16px; color: #5e5e5e; font-weight: 600; margin-bottom: 12px;
                              }     
                         }
                    }
               }
          }
     }
}

// blog filter tags 
.filter-pill-contain{
     display: flex;
     gap: 8px;
     flex-wrap: wrap;
     margin-bottom: 0px;
     .filter-pill{
       padding: 6px 10px;
       background-color: #F0F9FF;
       color: #026AA2 !important;
       border-radius: 100px;
       font-size: 14px !important;
       font-weight: 500 !important;
       line-height: 1 !important;
       &.tt{
         background-color: #EEF4FF;
         color: #3538CD !important;
       }
       &.trends{
         background-color: #FFF6ED;
         color: #C4320A !important;
       }
     }
   }

   .card-contain{
     .mob-block-only{
          display: none;
     }
     width: 100%;
     display: flex;
     align-items: start;
     h5{
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
     }
     .input-select{
          border-radius: 8px;
          border: 1px solid #D0D5DD;
          background: #FFF;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          height: 36px;
          padding: 0px 8px;
     }
     .check-contain{
          width: 20%;
          .check{
               display: flex;
               align-items: center;
               gap: 8px;
               margin-bottom: 16px;
               input{
                    height: 18px;
                    width: 18px;
                    border-radius: 4px;
               }
               label{
                    font-size: 14px;

               }
          }
     }
     .card-contain-inner{
          width: 80%;
          display: flex;
          flex-wrap: wrap;
          .blog-inner{
               width: 50%;
          }
     }
   }

   @media (max-width: 991.98px) {
     .card-contain{
          .check-contain{
               width: 40%;
               padding-left: 20px;
          }
          .card-contain-inner{
               width: 60%;
               display: flex;
               flex-wrap: wrap;
               .blog-inner{
                    width: 100%;
               }
          }
        }
 }

 @media (max-width: 767.98px) {
     .card-contain{
          .mob-block-only{
               display: block;
          }
          .web-block-only{
               display: none;
          }
          flex-wrap: wrap;
          .check-contain{
               width: 100%;
               padding-left: 20px;
               margin-bottom: 24px;
          }
          .card-contain-inner{
               width: 100%;
               display: flex;
               flex-wrap: wrap;
               .blog-inner{
                    width: 100%;
               }
          }
        }

 }