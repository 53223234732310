.events-details{
     .event-banner-img{
          img{
               margin: 35px 0px 50px 0px; max-height: 550px; width: 100%; object-fit: contain;
               @media (max-width: 991.98px) {
                    margin: 30px 0px; max-height: 220px;
               }
               @media (max-width: 991.98px) {
                    margin: 20px 0px;
               }
          }
     }
     p.breadcrumb{
          font-size: $p; font-weight: 500; color: $c-dark-grayish; margin-bottom: 30px;
          @media (max-width: 991.98px) {
               margin-bottom: 20px;
          }
          @media (max-width: 767.98px) {
               margin-bottom: 15px;
          }
     }
     .event-information{
          padding-bottom: 110px;
          @media (max-width: 991.98px) {
               padding-bottom: 70px;
          }
          @media (max-width: 767.98px) {
               padding-bottom: 40px;
          }
          p{
               color: #000; font-weight: 500;
          }
     }
}