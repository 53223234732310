.our-partners-details{
     padding-bottom: 110px;
          @media (max-width: 991.98px) {
               padding-bottom: 70px;
          }
          @media (max-width: 767.98px) {
               padding-bottom: 40px;
          }
     p{
          color: #000; font-weight: 500;
     }
     .our-partners{
          h3{
               margin: 30px 0px 20px 0px; font-weight: 600;
          }
          .partners-icons{
               ul{
                    display: flex; flex-direction: row; flex-wrap: wrap; 
                    li{
                        width: 20%; list-style: none; padding: 30px 40px; display: inline-block; vertical-align: middle;
                              @media (max-width: 1199.98px) {
                                   padding: 30px;
                              }
                              @media (max-width: 991.98px) {
                                   width: 25%;
                              }
                              @media (max-width: 767.98px) {
                                   width: 33.33%;
                              }
                              @media (max-width: 575.98px) {
                                   width: 50%; padding: 22px 35px;
                              }
                         img{
                              max-height: 47px; height: auto; width: auto;
                         }
                    }
               }
          }
     }
}

