.footer{
    background-color: $c-dark-grayish; color: $c-white; padding: 112px 0;

    @media (max-width: 991.98px) {
        padding: 80px 0;
    }
    @media (max-width: 575.98px) {
        padding: 50px 0;
    }

    ul{
        list-style: none;

        li{
            line-height: normal; margin-bottom: 10px;

            .link-action{
                font-size: $f-16; color: $c-white; line-height: 22px; display: inline-block;

                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }

    .f-top{
        display: flex; flex-wrap: wrap; justify-content: space-between; margin: 0 -10px;

        @media (max-width: 1399.98px) {
            margin: 0 -7px;
        }
        @media (max-width: 1199.98px) {
            margin: 0 -15px; justify-content: inherit;
        }
    
        .f-part{
            width: 20%; padding: 0 10px;

            @media (max-width: 1399.98px) {
                padding: 0 7px;
            }
            @media (max-width: 1199.98px) {
                padding: 0 15px; width: 33.33% !important; margin-bottom: 30px;
            }

            @media (max-width: 767.98px) {
                width: 50% !important; 
            }

            @media (max-width: 575.98px) {
                width: 100% !important; margin-bottom: 0;
            }
    
            &:last-child{
                margin-right: 0;
            }
    
            &.data-cloud{
                width: 19%;

                @media (max-width: 1199.98px) {
                    width: 23%;
                }

                @media (max-width: 575.98px) {
                    width: 100%;
                }
            }
    
            .f-heding{
                padding-bottom: 20px; margin-bottom: 20px; position: relative; display: block; font-size: $f-16; font-weight: $weight-500;

                @media (max-width: 575.98px) {
                    padding: 15px 25px 15px 0; margin-bottom: 0px; position: relative; font-weight: $weight-600; font-size: 17px;

                    &:before{
                        content: ""; position: absolute; top: 17px; right: 7px; left: auto; height: 16px; width: 2px; background-color: $c-white; transition: all 0.3s ease-in-out;
                    }
                }
    
                &::after{
                    content: ""; width: 100%; height: 2px; background-color: $c-white; position: absolute; bottom: 0; left: 0;

                    @media (max-width: 575.98px) {
                        position: absolute; top: 24px; right: 0px; left: auto; height: 2px; width: 16px;
                    }
                }

                &.open{
                    &:before{
                        transform: rotate(90deg);
                    }
                }
                
            }

            ul{
                @media (max-width: 575.98px) {
                    margin-bottom: 0; max-height: 0; overflow: hidden; transition: max-height 0.6s ease-in-out;

                    &.ul-visible {
                        max-height: 400px; padding-bottom: 10px; transition: max-height 0.6s ease-in-out;
                    }
                }
            }
        }
    }

    .f-botttom{
        border-top: 2px solid $c-white; margin-top: 23px; padding-top: 36px; display: flex; justify-content: space-between;

        @media (max-width: 991.98px) {
            margin-top: 0;
        }
        @media (max-width: 767.98px) {
            display: block;
        }
        @media (max-width: 575.98px) {
            margin-top: 25px; padding-top: 25px;
        }

        .lft-links{
            display: flex; width: 47%;

            @media (max-width: 1199.98px) {
                width: 46%;
            }
            @media (max-width: 991.98px) {
                width: 57%; justify-content: space-between;
            }
            @media (max-width: 767.98px) {
                width: 100%; justify-content: start;
            }
            @media (max-width: 575.98px) {
                display: block; width: 100%;
            }
            .f-part{
                width: 215px; margin-right: 26px; 

                @media (max-width: 1199.98px) {
                    margin-right: 12px; width: 225px;
                }
                @media (max-width: 767.98px) {
                    width: 50% !important; margin-bottom: 15px; 
                }
                @media (max-width: 575.98px) {
                    width: 100% !important; margin-bottom: 15px;
                }

                &:last-child{
                    margin: 0;
                }
                address{
                   font-style: normal; line-height: 20px; width: 215px;
                   @media (max-width: 575.98px) {
                    width: 215px;
                    }
                }
                .link-action{
                    color: $c-white; margin-bottom: 0; display: block;
                    &.email-link{
                        margin-bottom: 18px;
                    }
                }
                .title{
                    font-weight: $weight-600; margin-bottom: 5px; display: block;
                }
            }
        }

        .social-media{
            display: flex; flex-direction: column;
            @media (max-width: 991.98px) {
               margin-bottom: 15px; text-align: right; margin-bottom: 25px;
            }
            @media (max-width: 767.98px) {
                 text-align: left; margin-bottom: 20px;
            }
            @media (max-width: 575.98px) {
                 text-align: center;
            }
            ul{
                margin-bottom: 20px;
                @media (max-width: 1199.98px) {
                    margin-bottom: 10px;
                 }
                li{
                    display: inline-block; vertical-align: middle; padding: 0 8px; margin-bottom: 0;        
                    @media (max-width: 991.98px) {
                        padding: 0 5px;
                    }

                    .link-action{
                        font-size: 0; line-height: inherit;
                    }
                }
            }
        }

        .social-media ul {
            border-bottom: 1px solid #868789;
            padding-bottom: 11px;
        }

        .inded_followers {
            margin: auto;
            margin-top: 0;
            margin-bottom: 0;
        }

        .rgt-logo{
            display: flex; justify-content: space-between; width: 60%;

            @media (max-width: 1199.98px) {
                width: 50%;
            }
            @media (max-width: 991.98px) {
                width: 35%; display: block; text-align: right;

                img{
                    display: inline-block;
                }
            }
            @media (max-width: 767.98px) {
                width: 100%; text-align: center; border-top: 2px solid $c-white; margin-top: 25px; padding-top: 30px; display: flex;
            }
            @media (max-width: 575.98px) {
                display: block;
            }
        }
    }
}

.follow-container {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    padding-left: 8px;

    .logo {
        width: 26px;
        height: 22px;
        vertical-align: middle;
        border-radius: 3px 0 0 3px;
        background: #006097;
    }
    .follow-btn--no-count {
        border: 0;
        border-left: 1px solid #FFFFFF;
        background: #006097;
        color: #FFFFFF;
        padding: 11px 8px;
        margin-left: -3px;
        border-radius: 0 3px 3px 0;
        font-size: 14px;
        vertical-align: middle;
        cursor: pointer;
        outline: 0;
        height: 20px;
        line-height: 0;
    }
    .following-btn--no-count {
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 0 3px 3px 0;
        background: #F3F6F8;
        color: rgba(0,0,0,0.6);
        padding: 10px 8px;
        margin-left: -3px;
        font-size: 14px;
        vertical-align: middle;
        cursor: pointer;
        outline: 0;
        height: 20px;
        line-height: 0;
    }
    .fill-white {
        fill: #ffffff;
    }
    .fill-blue{
        fill: #006097;
    }
}

