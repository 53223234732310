h3{
     font-weight: $weight-500; margin-bottom: 5px;
}
.esteemed-icon{
     list-style: none; margin: 0;
     @media (max-width: 1199.98px) {
          margin: 0 -15px;
     }
     li{
          width: 33.33%; padding: 25px 35px; display: inline-block; vertical-align: middle;
          @media (max-width: 1199.98px) {
               padding: 25px;
          }
          @media (max-width: 575.98px) {
               width: 50%; padding: 20px 30px;
          }
          img{
               max-height: 110px; margin: 0 auto;  height: 100%; object-fit: scale-down;
          }
          h5{
               font-size: $f-18; line-height: 34px; color: $c-midnight-blue; font-weight: $weight-600; margin-top: 10px;
               text-align: center; letter-spacing: 0.5px; word-spacing: 1px;
          }
     }
}

.half-circle-bottom {
     width: 200px;
     height: 100px;
     background-color: #3498db;
     border-bottom-left-radius: 100px;
     border-bottom-right-radius: 100px;
 }
