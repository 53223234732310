@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&family=Rubik:wght@400;500;600&family=Tangerine:wght@400;700&display=swap');


/* Fonts Size
========================================================================== */
$h1: 40px;
$h2: 40px;
$h3: 25px;
$h4: 22px;
$h5: 20px;
$h6: 18px;
$p: 16px;
$f-13: 13px;
$f-16: 16px;
$f-18: 18px;
$f-20: 20px;
$f-22: 22px;
$f-25: 25px;
$f-26: 26px;
$f-32: 32px;
$f-34: 34px;
$f-36: 36px;
$f-38: 38px;
$f-40: 40px;
$f-46: 46px;
$f-52: 52px;
$f-73: 73px;

/* Fonts Weight
========================================================================== */
$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;


/* Color's
========================================================================== */
//$primary-color: #1c3d85;
//$secondary-color: #ffffff;
$c-dark-grayish: #58595b;
$c-dark-grayish1: #838485;
$c-dark-grayish2: #404041;
$c-midnight-blue: #00356f;
$c-navy-blue: #01356f;
$c-navy-blue1: #13346b;
$c-navy-blue2: #003673;
$c-light-cyn-blue: #e6e7e8;
$c-red: #d6212a;
$c-red1: #b6312d;
$c-red2: #b5312d;
$c-black: #000000;
$c-white:#ffffff;
$c-mischka: #a6a8ab;
$c-solitude: #e5e6e7;
$c-solitude1: #e7e8e9;
$c-solitude2: #e5e5e5;
$c-pacific-blue: #00a0e7;
$c-pacific-blue1: #009ee2;
$c-pacific-blue2: #85bbe4;
$c-blue-eyed: #84bae3;


/* Fonts Family
========================================================================== */
$primary-font: 'Raleway', sans-serif;
$secondary-font: 'Tangerine', cursive;
$rubik-font: 'Rubik', sans-serif;