.contact{
    .forms-btns{
        list-style: none; display: flex; flex-wrap: wrap; margin: 0 -68px; padding: 70px 0 50px 0; justify-content: space-between; text-align: center; justify-content: flex-start; 
        @media (max-width: 1199.98px) {
            margin: 0 -20px;
        }
        @media (max-width: 767.98px) {
            padding: 30px 0; 
        }
        li{
            padding: 68px; width: 33.33%; position: relative;
            @media (max-width: 1199.98px) {
                padding: 20px;
            }
            @media (max-width: 767.98px) {
                width: 50%;
            }
            @media (max-width: 575.98px) {
                width: 100%;
            }
            .action-btn{
                padding: 20px; height: 290px; font-size: $f-25; border-radius: 28px; width: 100%; background-color: $c-solitude; color: #13346b; font-weight: $weight-500; display: block; display: flex; align-items: center; justify-content: center; overflow: hidden; position: inherit;
                @media (max-width: 1199.98px) {
                    height: 220px;
                }
                @media (max-width: 575.98px) {
                    height: auto; font-size: $f-18; line-height: 24px;
                }
                
                &::before{
                    content: ""; display: inline-block; position: absolute; width: 92%; height: 92%; box-sizing: border-box; z-index: -2; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 28px; transition: all 0.85s ease-in-out;
                }

                &:hover{
                    z-index: 9; color: $c-white;

                    &::before {
                        background: #469bdb; width: 100%; height:100%;
                    }
                }
            }
        }
    }
}
.contact-fields{
  background-color: $c-solitude1;
}
.form-wrapper{
    max-width: 730px; margin: 0 auto;
    form{
      position: relative;
    }
    h2{
      margin-bottom: 70px;  
      @media (max-width: 575.98px) {
        margin-bottom: 30px;
      }
    }
    .item{
        margin-bottom: 35px;
        
        label{
             width: 21%; display: inline-block; vertical-align: middle; font-size: $f-16; color: $c-dark-grayish; font-weight: 600;
            @media (max-width: 767.98px) {
              width: 100%; margin-bottom: 10px; 
            }
        }
        .rgt{
          width: 70%; display: inline-block; vertical-align: top;

          > span{
            width: 50%; margin-right: 0;
            @media (max-width: 575.98px) {
              width: 100%;
            }
          }
          @media (max-width: 767.98px) {
            width: 100%;
          }
        }
        .invalid-feedback{
          font-size: 14px; padding: 5px 0 0 15px; width: 80%; float: right; color: #b52337; font-weight: 600;
          @media (max-width: 767.98px) {
            width: 100%; padding-left: 5px; padding-top: 5px;
          }
        }
        input{
          height: 30px; width: 79%; font-size: $f-16; padding: 2px 10px; border-radius: 12px; display: inline-block; vertical-align: middle; color: $c-dark-grayish; font-family: $primary-font;
          @media (max-width: 767.98px) {
            width: 100%;
          }
        }
        textarea{
          height: 85px; width: 79%; font-size: $f-16; padding: 2px 10px; border-radius: 12px; display: inline-block; vertical-align: top; color: $c-dark-grayish; font-family: $primary-font;
          @media (max-width: 767.98px) {
            width: 100%;
          }
        }
        ::-webkit-input-placeholder{
            font-size: $f-16; color: $c-dark-grayish; 
        }
        ::-moz-placeholder{
            font-size: $f-16; color: $c-dark-grayish;
        }
        :-ms-input-placeholder{
            font-size: $f-16; color: $c-dark-grayish;
        }
        :-moz-placeholder {
            font-size: $f-16; color: $c-dark-grayish;
        }
        input[type="radio"] {
            opacity: 0; width: 100%; position: absolute; z-index: 9; height: 23px;
            +.label-radio {
                cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; position: relative; padding-left: 36px; margin: 0 0 0 5px;
                &::before {
                    content: ""; transition: all 250ms cubic-bezier(.4,.25,.3,1); width: 24px; height: 24px; border-radius: 50%; background-color: $c-white; border: 0px solid $c-midnight-blue; font-size: 0; position: absolute; left: -5px; top: 50%; transform: translateY(-50%);
                }
                &::after {
                    content: "";  transition: all 250ms cubic-bezier(.4,.25,.3,1); width: 0; height: 0; border-radius: 50%; background-color: $c-midnight-blue; position: absolute; left: 0; top: 50%; transform: translateY(-50%);
                }
            }
            &:checked{
                + .label-radio{
                    &::before {
                        background-color: transparent; width: 24px; height: 24px; border-width: 2px;
                    }
                    &::after {
                        width: 14px; height: 14px;
                    }
                }
            } 
        }
        span{
            margin: 0 40px 15px 0; display: inline-block; vertical-align: middle; position: relative;
            input{
                width: auto;
            }
        }
        &.submit-item{
            text-align: center;
        }
    }
    .item.submit-item{
        margin-bottom: 15px !important;
    }
    &.form-bg{
      background-color: $c-midnight-blue; margin: 0 0 0 auto; max-width: 513px;
      @media (max-width: 991.98px) {
        margin: 0 auto; 
      }
      .item{
        margin-bottom: 23px;
        label{
          width: 100%; font-size: $f-25; color: $c-white; margin: 0 0 10px 18px; 
          @media (max-width: 575.98px) {
            font-size: $f-18;
          }
        }
        .invalid-feedback{
          width: 100%; color: #ff8495; padding: 6px 0 5px 20px; 
        }
        input{
          width: 100%; font-size: $f-25; padding: 12px 18px; color: $c-midnight-blue; font-weight: $weight-700; height: 40px; border-radius: 20px; line-height: 33px;
          @media (max-width: 575.98px) {
            font-size: $f-18;
          }
        }
        ::-webkit-input-placeholder{
          font-size: $f-25; color: $c-midnight-blue; 
        }
        ::-moz-placeholder{
          font-size: $f-25; color: $c-midnight-blue;
        }
        :-ms-input-placeholder{
          font-size: $f-25; color: $c-midnight-blue;
        }
        :-moz-placeholder {
          font-size: $f-25; color: $c-midnight-blue;
        }
        &.submit-item{
          text-align: left; margin: 4px 0 0 0; display: inline-block; 
        }
      }
      .thanks-msg{
        background: $c-midnight-blue;
        h4{
          color: $c-white; line-height: 30px; font-weight: $weight-600;
        }
      }
    }
}
.forms-links{
  background-color: $c-dark-grayish;
  .link-action{
    color: $c-white; font-size: $f-25; font-weight: $weight-400; padding: 16px 50px; display: inline-block;
    @media (max-width: 1199.98px) {
      padding: 15px 25px;
    }
    @media (max-width: 991.98px) {
      font-size: $f-20; padding: 15px 20px;
    }
    &.active {
      color: #469bdb;
    }
  }
  .slick-slider{
    padding: 0 80px;
    @media (max-width: 767.98px) {
      padding: 0 25px;
    }
    .slick-arrow{
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="32px"><image x="0px" y="0px" width="20px" height="32px" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAQAAAC4a5A9AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfnCRISHSGcMnejAAABEElEQVQ4y43UvyvEYRgA8OcYLBKhTERRdkmG22yKYrHIZnHJRNkpg0FGoxuU/AEWA0W3mQ1XMlnFcKWP4dL5cd973/cd3vd5+tT79L5Pb0lkjJN47Mpg61GJkZCapzjQ0xl1q2FRiE5s2qc3o82omK3hphUXsWMc/sy0QyUPWPqd/c+mNHwY/5v/y1Zx166Y3+ERjtpX/TO4xUrRLXxvJrxrmCy+1eayjHulTq8Uwh6OU28erjGTbI3oiteIGMtoNmEDl+mjQ5jFs8E0DH2eUE7DEKrYzoGhgoscGOZR15+GYUAdc2kYwgW2cmDYwXkODGU86U3DMOSl1QepZrjCZg4Mu6gaTsOwgP1S1rd3FrUvoapvWuDsnq0AAAAASUVORK5CYII=" /></svg>') !important;
      background-repeat: no-repeat; height: 32px; margin-top: -16px;
      &.slick-prev{
        left: 0;
      }
      &.slick-next{
        right: 0;
      }
      &.slick-disabled{
        display: none !important;
      }
    }
  } 
}

// This is after submitted successfully START
.submitted-success, .loader-active{
  max-height: 400px; overflow: hidden;
  .thanks-msg,.loader-sec{
    position: absolute; top: 0; left: 0; background: #e7e8e9; width: 100%; height: 100%; text-align: center; z-index: 45;
    h4{
      font-weight: $weight-500; margin-top: 15px;
    }
  }
  .main-container {
    display: flex; flex-flow: column; justify-content: center; align-items: center; height: 100%;
    .check-container {
      width: 100px; height: 120px; display: flex; flex-flow: column; align-items: center; justify-content: space-between;
      .check-background {
        width: 100%; height: calc(100% - 1.25rem); background: linear-gradient(to bottom right, #5de593, #41d67c); box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset; transform: scale(0.84); border-radius: 50%; animation: animateContainer 0.75s ease-out forwards 0.75s; display: flex; align-items: center; justify-content: center; opacity: 0; 
        svg {
          width: 65%; transform: translateY(0.25rem); stroke-dasharray: 80; stroke-dashoffset: 80; animation: animateCheck 0.9s forwards 1.25s ease-in-out;
        }
      }   
      .check-shadow {
        bottom: calc(-15% - 5px); left: 0; border-radius: 50%; background: radial-gradient(closest-side, rgba(73, 218, 131, 1), transparent); animation: animateShadow 0.75s ease-out forwards 0.75s;
      }
    }
  }


}

.loader-active{
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #b6312d #b6312d;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      
}



@keyframes animateContainer {
	0% {
		opacity: 0; transform: scale(0);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	25% {
		opacity: 1; transform: scale(0.9); box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	43.75% {
		transform: scale(1.15); box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	62.5% {
		transform: scale(1);
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
	}
	81.25% {
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
	100% {
		opacity: 1; box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
}

@keyframes animateCheck {
	from {
		stroke-dashoffset: 80;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes animateShadow {
	0% {
		opacity: 0; width: 100%; height: 15%;
	}
	25% {
		opacity: 0.25;
	}
	43.75% {
		width: 40%; height: 7%; opacity: 0.35;
	}
	100% {
		width: 85%; height: 15%; opacity: 0.25;
	}
}
// This is after submitted successfully END

// Nodal and Appellate Contact Page START
.address-wrap{
  display: flex; flex-wrap: wrap; justify-content: space-evenly; padding-bottom: 70px; margin: 0 -30px;
  @media (max-width: 1199.98px) {
    margin: 0 -40px;
  }
  @media (max-width: 991.98px) {
    margin: 0;
  }
  .address-box{
    padding: 30px; width: 50%; 
    @media (max-width: 1199.98px) {
      padding: 15px;
    }
    @media (max-width: 991.98px) {
      width: 70%;
    }
    @media (max-width: 767.98px) {
      width: 100%; padding: 15px 0;
    }
    .content{
      background-color: $c-solitude; border-radius: 30px; padding: 30px; height: 100%;
      @media (max-width: 1199.98px) {
        padding: 20px;
      }
    }
    h3{
      text-align: left; font-weight: $weight-600; color: $c-midnight-blue; margin-bottom: 10px;
    }
    ul{
      list-style: none;
      li{
        word-break: break-all; margin-bottom: 10px; line-height: 26px;
      }
    }
  }
}
// Nodal and Appellate Contact Page End

// Contact form Popup START
.popupform{
  h2{
    margin-bottom: 40px;
  }
}
// Contact form Popup END


// CoColocation Popup START
.colocation_popup
{
  flex: 1 1;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.colocation_popup .getintouch{
  padding: 12px 20px;
  font-size: 16px;
  color: white;
  background-color: #003673;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.colocation_popupform{
  h2{
    margin-bottom: 30px;
  }
}
.colocation_popupform .item{
  margin-bottom: 25px;
}

// Contact form Popup END




  

.modal { 
  position: relative;
  padding: 20px;
  border-radius: 5px;
  transition: all 5s ease-in-out; 
  .close1 {
    position: absolute;
    top: 13px;
    right: 35px;
    cursor: pointer;
    font-size: 50px;
    color: #13346b;
      @media (max-width: 767.98px) {
          font-size: 45px; right: 32px; top: 10px;
      }
    &:hover {
      color: $c-dark-grayish;
    }
  }
}

.ishan-map.sec-data-footprint {
  background: #009bdc;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;

  .footprint-txt {
    color: #fff;
    font-size: 40px;
  }
}

.ishan-map.sec-co-locations {
  background: #fff;
  position: relative;
  margin-top: 50px;
  margin-bottom: 150px;
}
.ishan-map {
  padding: 20px 0;
}

.ishan-map .container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.locations {
  flex: 1;
  max-width: 30%;
  text-align: center;
  
}

.locations img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.locations .label {
  margin-top: 5px;
  font-size: 18px;
  color: #606060;
  text-align: left;
  margin-bottom: 2px;
}

.locations .cta-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #003673;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: right;
}

.locations .cta-button:hover {
  background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
  .locations {
      max-width: 100%;
      margin-bottom: 20px;
  }
}


// updates css 
.contact-link-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    gap: 5%;
    padding-top: 80px;
    padding-bottom: 40px;
  .form-box{
    padding: 20px 30px;
    border: 1px solid #dadada;
    background-color: #fff;
    display: flex;
    align-items: start;
    width: 30%;
    cursor: pointer;
    margin-bottom: 40px;
    transition: 0.3s;
    position: relative;
    &::before{
      content: '';
      height: 3px;
      width: 0px;
      background-color: #b52337;
      position: absolute;
      top: -1px;
      left: 0px;
      transition: 0.3s;
    }
    .ic-img{
      width: 48px;
      margin-right: 20px;
    }
    .title{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .link-name{
      font-size: 14px;
      height: 20px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .ic-arrow{
      margin-left: 0px;
      transition: 0.3s;
    }
    &:hover{
      box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
      &::before{
        width: 100%;
      }
      .ic-arrow{
        margin-left: 4px;
      }
    }
    
  }
}
.ds-desc{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

@media (max-width: 1200px) {
  .contact-link-wrap{
    gap: 4%;
    .form-box{
      width: 48%;
    }
  }
}
@media (max-width: 992px) {
  .ishan-map.sec-co-locations{
    .locations{
      width: 45%;
      max-width: 100%;
      flex: 1 0 auto;
      margin-bottom: 64px;
    }
  }

}
@media (max-width: 767.98px) {
  .contact-link-wrap{
    .form-box{
      width: 100%;
      margin-bottom: 24px;
      .title{
        font-size: 18px;
      }
    }
    
  }
  .ishan-map.sec-co-locations{
    margin-bottom: 20px;
    .locations{
      width: 100%;
      flex: 1 0 auto;
    }
  }
}

// Internet Services Page 
// banner-form
.internet-banner{
  .banner-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
}
@media (max-width: 992px) {
  .internet-banner{
    // max-height: 950px;
    .banner-content{
      // display: flex;
      // align-items: start;
      // justify-content: start;
      // flex-direction: column;
      // position: relative !important;
      .content-blk{
        // margin-bottom: 24px;
        max-width: 98%;
      }
    }
    .banner-img-mob, .banner-img-web{
      // min-height: 950px !important;
    }
    // &.video.hero-banner .banner-content {
    //   transform: unset;
    //   top: -920px;
    // }
    // .form-blk{
    //   width: 100%;
    //   .banner-form{
    //     margin-left: unset;
    //   }
    // }
  }
}


.banner-form{
  z-index: 3;
  width: 320px;
  max-width: 100%;
  background: #fff;
  padding: 16px 20px;
  border-radius: 8px;
  h2{
    font-size: 24px;
    font-weight: 600 ;
    margin-bottom: 16px;
    line-height: 1.2;
  }
  .item{
    margin-bottom: 16px;
    input, textarea{
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      width: 100%;
      &::placeholder{
        font-size: 14px;
      }
    }
    input{
      height: 32px;
    }
    .invalid-feedback{
      width: 100%;
    float: none;
    padding: unset;
    margin-top: 10px;
    margin-left: 5px;
    font-size: 12px;
    }
  }
  label{
    display: none !important;
  }
  .btn-pacific-blue{
    border: 1px solid transparent;
    font-size: 20px;
    line-height: 1;
    width: 166px;
    font-weight: 600;
  }
  .btn-pacific-blue:hover{
    border: 1px solid #00356f;
  }
}
.form-wrapper .item.submit-item.mb-0{
  margin-bottom: 0px !important;
}
// new box css 
.box-imp-title{
  font-weight: 600;
  // margin-top: 20px;
  // margin-bottom: 40px;
  margin: 20px 0px 40px 83px;
}
.enquire-now-side-btn{
  position: fixed;
  background-color: #00356f;
  padding: 12px 12px 6px 12px;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  border-radius: 100px 100px 0px 0px;
  width: 140px;
  border: 1px solid transparent;
  font-weight: 500;
  right: -56px;
  top: 50%;
  transform: rotate(270deg);
  z-index: 9;
  cursor: pointer;
  display: none;
  &:hover{
       background-color: #fff;
       color: #00356f;
       border: 1px solid #00356f;
  }
}
@media (max-width: 992px) {
  .form-blk{
    display: none;
  }
  .enquire-now-side-btn{
    display: block;
  }
  .banner-form{
    background: #e6e7e8;
    padding: 0px;
  }
}
@media (max-width: 1399.98px) {
  .box-imp-title{
      margin-left: 11px;
       padding-left: 40px;
  } 
}

.box-impower-wrap{
  display: flex;
  .box-empower{
    width: 50%;
  }
  .emp-box{
    padding: 20px 48px;
    border: 1px solid #00a0e3;
    border-radius: 8px;
    position: relative;
    margin-top: 40px;
    min-height: 143px;
    ul li{
  //     max-width: 80%;
      line-height: 1.2;
      color: #00356f !important;
      margin-inline: unset !important;
      list-style: disc;
    }
  }
  .emp-box.border{
       &::after{
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: #00a0e3;
            right: -85px;
            top: 0px;
         }
    }

    .serv-storage-list{
      // margin: 0px 0px 20px 83px;
      span{

        display: inline-block;
        font-size: 22px;
        line-height: 38px;
        color: #00356f;
        font-weight: 600;
      }
      p{
        padding-left: 24px;
      }
    }
    
}
.pl-list{
  padding-left: 122px;
}
.empowering-right.pl-list{
  .box-empower:nth-child(1){
    padding-right: 48px;
  }
  .box-empower:nth-last-child(1){
    padding-left: 48px;
    border-left: 1px solid #00356f;
  }
}
.empowering.section-space{
  .mob-img{
    display: none;
  }
}
.d-block{
  display: block;
}
.w-100{
  width: 100% !important;
}
.fw-600{
  font-weight: 600;
}
.mb-5{
  margin-bottom: 48px;
}
.mt-4{
  margin-top: 24px;
}
.mt-5{
  margin-top: 48px;
}
.img-fluid{
  max-width: 100%;
}
.text-center{
  text-align: center;
}

@media (min-width: 1400px) {
  .box-impower-wrap{
       .emp-box.border{
            &::after{
                 right: -9%;
              }
         }
     }
     .pl-list{
      padding-left: 83px;
    }
}
@media (max-width: 991.98px) {
  .box-impower-wrap{
       flex-direction: column;
       gap: 20px;
       .box-empower{
         width: 100%;
       }
       .emp-box{
         margin-top: 20px;
         
       }
       .emp-box.border{
            &::after{
                 display: none;
              }
         }
         
     }
     .pl-list{
      padding-left: 30px;
    }
    .empowering-right.pl-list .box-empower:nth-child(1) {
      padding-right: 0px;
  }
    .empowering-right.pl-list .box-empower:nth-last-child(1) {
      padding-left: 0px;
      border-left: unset;
  }
}
@media (max-width: 767px) {
  .box-imp-title{
    margin-left: 11px;
       padding-left: 0px;
  } 
  .box-impower-wrap{
       
      
       }
       .emp-box{
         padding: 20px 20px 20px 48px;
         
       }
       .pl-list{
        padding-left: 10px;
      }
      .box-impower-wrap .serv-storage-list span {
        display: inline;
        font-size: 20px;
        
    }
    
.empowering.section-space{
  .mob-img{
    display: block;
  }
  .web-img{
    display: none;
  }
}
.scrolling-img{
  min-width: 992px;
  overflow: auto;
}
}

.table-responsive{
  overflow-x: auto;
  padding-bottom: 30px;
}
.plans-table-section{
  background-color: #e5e6e7;
  padding: 30px 0px 60px 0px;
  h2{
       font-weight: 600;
       margin-bottom: 40px;
  }
  .plans-table{
       
            // border: 1px solid #00356f;
            border-spacing: 0px;
            border-collapse: collapse;
       
       tr td, tr th{
            font-size: 14px;
            line-height: 16px;
            border: 1px solid #00a0e3;
            vertical-align: middle;
            padding: 0px 8px;
       }
       tr td{
            height: 80px;
            min-width: 130px;
       }
       thead tr th{
            height: 36px;
       }
       thead th:nth-child(1), tbody th:nth-child(1){
            text-align: left;
       }
       .with-btn{
            td{
                 position: relative;
                 padding-bottom: 14px;
                 .enquire-now-btn{
                      position: absolute;
                      display: block;
                      background-color: #00356f;
                      padding: 6px 12px;
                      font-size: 12px;
                      color: #fff;
                      line-height: 1.2;
                      text-align: center;
                      border-radius: 100px;
                      left: 50%;
                      transform: translateX(-50%);
                      bottom: -14px;
                      width: 100px;
                      border: 1px solid transparent;
                      font-weight: 500;
                      &:hover{
                           background-color: #fff;
                           color: #00356f;
                           border: 1px solid #00356f;
                      }
                 }
            }
       }
  }
}


section.mob_plan_sec {
  display: none;
}


// awards page
.mb-5{
  margin-bottom: 48px;
}

.award-banner{
  background: linear-gradient(102deg, #124178 -1.12%, rgba(182, 49, 45, 0.90) 100%);
  // background-image: url('../assest/images/award-bg-banner.png');
  min-height: calc(100vh - 100px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .banner-row{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .left-content{
    width: 50%;
    .title{
      font-size: 58px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.33;
      color: #fff;
      span{
        background-color: #B6312D;
        padding: 4px;
        max-width: max-content;
        display: inline;
      }
    }
    .para{
      color: #fff;
      font-size: 24px;
      span{
        background-color: #B6312D;
        padding: 4px;
        max-width: max-content;
        display: inline;
      }
    }
  }
  .right-content{
    width: 45%;
    img{
      max-width: 100%;
      display: inline-block;
      text-align: right;
    }
  }
}
.award-consider{

  .consider-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .left-list{
      width: 60%;
    }
    .right-img{
      width: 40%;
      text-align: right;
      img{
        display: inline-block;
      }
    }
    li{
      span{
        color: rgba(0, 158, 226, 1);
      }
    }
  }
  .advantage-rowinner{
    padding: 0px 40px;
    img{
      display: inline-block;

    }
    h4, p{
      margin: 0px  !important;
    }
  }
}





@media only screen and (max-width: 991.98px) {
  .award-consider{
    .consider-row{
      flex-wrap: wrap;
      flex-direction: column-reverse;
      .left-list{
        width: 100%;
      }
      .right-img{
        width: 100%;
        text-align: left;
        margin-bottom: 24px;
        img{
          display: inline-block;
        }
      }
      
    }
    .advantage-rowinner{
      padding: 0px 0px;
    }
  }
  .award-banner{
    min-height: calc(100vh - 73px);
    padding: 40px 0px;
    .left-content{
      .title{
        font-size: 40px;
        
      }
      .para{
        font-size: 20px;
      }
    }
    
  }
}

@media only screen and (max-width: 767.98px) {
  .award-banner{
    background: linear-gradient(102deg, #124178 -1.12%, rgba(182, 49, 45, 0.90) 100%);
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    
    .banner-row{
      flex-direction: column-reverse;
      align-items: start;
    }
    .left-content{
      width: 100%;
      .title{
        font-size: 32px;
      }
      .para{
        color: #fff;
        font-size: 18px;
      }
    }
    .right-content{
      width: 100%;
      margin-bottom: 24px;
      img{
        text-align: left;
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  section.mob_plan_sec {
    display: block;
  }
  section.desk_plan_sec {
    display: none;
  }
}

video#myvid {
  object-fit: cover;
}




// Apple page css 
.text-primary{
  color: #003673 !important;
}
.fw-bold{
  font-weight: 600;
}
.mt-5{
  margin-top: 3rem;
}
.pt-5{
padding-top: 3rem;
}
.img-fluid{
  max-width: 100%;
}
.mb-2{
  margin-bottom: 8px;
}
.auth-text{
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  img{
    height: 21px !important;
    width: 21px !important;
    min-height: 24px !important;
    margin-right: 8px;
  }
}
.primary-title{
  h2{
    color: #003673 !important;
  }
}
.shadow-sect{
  position: relative;
  &::before{
    content: '';
    background:linear-gradient(92deg, rgba(217, 217, 217, 0.00) 1.63%, #E5E6E7 141.88%);
    height: 100%;
    width: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  
}
.apple-page{
  h2 .text-highlight{
    display: inline;
    background-color: #00356f;
    color: #fff;
  }
}


// planss 
.mb-4{
  margin-bottom: 1.5rem;
}
.f-18{
  font-size: 18px;
}
.f-24{
  font-size: 24px;
}
.mb-1{
  margin-bottom: 4px;
}
.ml-4{
  margin-left: 1.5rem;
}
.type-heading{
  padding: 12px 16px 12px 16px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
}
.table-heading{
  padding: 12px 16px 12px 16px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
  font-size: 16px;
}

.table-box{
  border: 1px solid #00a0e3;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 24px;

  .table-responsive{
    padding-bottom: 0px;
  }


.plans-table{
  width: 100%;
  tr td:nth-child(1), tr th:nth-child(1){
    border-left: 0px;
  }
  tr td:nth-last-child(1), tr th:nth-last-child(1){
    border-right: 0px;
  }
  tfoot tr th{
    border-bottom: 0px;
  }
  tfoot tr th, thead tr th{
    background-color: #EEF7FF;
    font-weight: 600;
  }
}
}
.plans-table tfoot td, .plans-table tfoot th {
  height: 72px;
}
.plans-table-section.cloud{
  background-color: #fff;
}
@media only screen and (max-width: 767.98px) {
  .type-heading{
    background-color: #EEF7FF;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .cloud-plan{
    .plan-item {
      padding: 0px !important;
      margin-bottom: 16px;
      .plan-inner{
        border-radius: 8px;
        padding: 0px !important;
        background-color: #fff !important;
        border: 1px solid #d8d8d8;
      }
    }
  }
  .cloud-plan .head{
    border-radius: 8px 8px 0px 0px;
    background: #EDEDED;
    font-size: 14px;
    padding: 8px 12px;
    text-align: center;
    font-weight: 600;
  }
  .cloud-plan .card-bdy{
    padding: 12px 24px;
    ul{
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      gap: 4px;
      li{
        width: calc(50% - 4px);
        color: #18181B !important;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        &::before{
          content: '';
          height: 4px;
          width: 4px;
          background-color: #222;
          border-radius: 100px;
          position: absolute;
          left: -10px;
          top: 6px;

        }
      }
    }
  }

  .cloud-plan .card-foot{
    border-radius: 0px 0px 8px 9px;
    background: #EEF7FF;
    font-size: 14px;
    padding: 8px 12px;
    text-align: center;
    color: #124178;
    font-weight: 600;
  }
}