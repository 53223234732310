h3{
     font-weight: $weight-500; margin-bottom: 5px;
}

.term-condition{
     ul, ol{
          list-style-position: outside; margin-left: 18px;

          li{
               margin-bottom: 15px;
          }
     }
}