
.spin-wrap{
    overflow: hidden; position: relative;

    .spin-round-wrap {
        position: static;
        list-style: none;
        .spin-round {
		    text-indent: -9999px; display: block; position: absolute; border: 1px solid #e86e79; width: 867px; height: 867px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; top: -110px; left: 95%; z-index: -1; animation-duration: 12s; animation-iteration-count: infinite; animation-timing-function: linear; animation-name: orbit;
            @media (max-width: 991.98px) {
                left: 90%; opacity: 0.65;
            }
            &.color-navy-blue{
                border: 1px solid #009ee2; top: 31%; left: auto; right: 94%;
                @media (max-width: 991.98px) {
                    right: 90%; top: 28%;
                }
                span{
                    background-color: #009ee2;
                }
            }
            .ballDot1 {
                display: block; position: absolute; width: 17px; height: 17px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; background-color: #d60013; bottom: 320px; left: 5px;
            }
            .ballDot2 {
                display: block; position: absolute; width: 17px; height: 17px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; background-color: #d60013; top: 320px; right: 5px;
            }
            .ballDot3 {
                display: block; position: absolute; width: 17px; height: 17px; -webkit-border-radius: 100%; -moz-border-radius: 100%;border-radius: 100%; background-color: #d60013; bottom: 5px; right: 320px;
            }
            .ballDot4 {
                display: block; position: absolute; width: 17px; height: 17px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; background-color: #d60013; top: 5px; left: 320px;
            }
		}
    }
}
@keyframes orbit { 
    from { 
        -webkit-transform:rotate(0deg) 
    } to { 
        -webkit-transform:rotate(360deg) 
    } 
}


// 404 Page Design START (I will create new file for CSS) 
.error-wrap{
    text-align: center; padding: 110px 0 140px;
        @media (max-width: 1440.98px) {
            padding: 100px 0px;
        }
        @media (max-width: 1199.98px) {
            padding: 80px 0px;
        }
        @media (max-width: 991.98px) {
            padding: 60px 0px;
        }

    .text-4{
        font-size: 200px; font-weight: 700; display: inline-block; vertical-align: middle; padding: 0 100px; color: $c-black;
        @media (max-width: 1199.98px) {
            font-size: 150px; line-height: 140px; padding: 0 90px;
        }
        @media (max-width: 767.98px) {
            font-size: 100px; line-height: 100px; padding: 0 65px;
        }
        @media (max-width: 575.98px) {
            font-size: 65px; line-height: 65px; padding: 0 45px;
        }
    }

    .error-heading{
        margin-top: 30px; font-size: 48px; text-transform: uppercase; font-weight: 600; color: $c-red2;
        @media (max-width: 1199.98px) {
            font-size: 42px;
        }
        @media (max-width: 767.98px) {
            font-size: 30px; margin-top: 15px;
        }
    }
    
    .error-page-load{
        display: inline-block; vertical-align: middle; margin-left: -50px;
        
        // transform: rotate(90deg);

        .dot-gathering::before, .dot-gathering::after{
            width: 110px; height: 110px; left: -65px;
            @media (max-width: 1199.98px) {
                width: 90px; height: 90px; left: -55px;
            }
            @media (max-width: 767.98px) {
                width: 70px; height: 70px; left: -45px;
            }
            @media (max-width: 575.98px) {
                width: 45px; height: 45px; 
                left: -35px;
            }
        }
    }
}
// 404 Page Design END (I will create new file for CSS)